
    import './styles.scoped.css';
    export default {
  "flash-with-motion": "awsui_flash-with-motion_1q84n_kln9a_93",
  "enter": "awsui_enter_1q84n_kln9a_93",
  "flash-body": "awsui_flash-body_1q84n_kln9a_107",
  "flash-message": "awsui_flash-message_1q84n_kln9a_107",
  "flash-header": "awsui_flash-header_1q84n_kln9a_107",
  "flash-content": "awsui_flash-content_1q84n_kln9a_108",
  "action-button-wrapper": "awsui_action-button-wrapper_1q84n_kln9a_109",
  "dismiss-button-wrapper": "awsui_dismiss-button-wrapper_1q84n_kln9a_110",
  "flash-icon": "awsui_flash-icon_1q84n_kln9a_133",
  "entering": "awsui_entering_1q84n_kln9a_146",
  "entered": "awsui_entered_1q84n_kln9a_167",
  "exiting": "awsui_exiting_1q84n_kln9a_272",
  "stack": "awsui_stack_1q84n_kln9a_294",
  "animation-running": "awsui_animation-running_1q84n_kln9a_294",
  "item": "awsui_item_1q84n_kln9a_294",
  "flash-list-item": "awsui_flash-list-item_1q84n_kln9a_295",
  "notification-bar": "awsui_notification-bar_1q84n_kln9a_296",
  "collapsed": "awsui_collapsed_1q84n_kln9a_316",
  "animation-ready": "awsui_animation-ready_1q84n_kln9a_316",
  "expanded-only": "awsui_expanded-only_1q84n_kln9a_316",
  "expanded": "awsui_expanded_1q84n_kln9a_316",
  "flash": "awsui_flash_1q84n_kln9a_93",
  "collapsible": "awsui_collapsible_1q84n_kln9a_380",
  "short-list": "awsui_short-list_1q84n_kln9a_386",
  "visual-refresh": "awsui_visual-refresh_1q84n_kln9a_386",
  "status": "awsui_status_1q84n_kln9a_603",
  "header": "awsui_header_1q84n_kln9a_603",
  "item-count": "awsui_item-count_1q84n_kln9a_604",
  "button": "awsui_button_1q84n_kln9a_605",
  "type-count": "awsui_type-count_1q84n_kln9a_640",
  "count-number": "awsui_count-number_1q84n_kln9a_640",
  "icon": "awsui_icon_1q84n_kln9a_674",
  "floating": "awsui_floating_1q84n_kln9a_712",
  "flashbar": "awsui_flashbar_1q84n_kln9a_720",
  "flash-refresh": "awsui_flash-refresh_1q84n_kln9a_766",
  "flash-list": "awsui_flash-list_1q84n_kln9a_295",
  "flash-focus-container": "awsui_flash-focus-container_1q84n_kln9a_785",
  "flash-text": "awsui_flash-text_1q84n_kln9a_812",
  "dismiss-button": "awsui_dismiss-button_1q84n_kln9a_110",
  "breakpoint-default": "awsui_breakpoint-default_1q84n_kln9a_853",
  "action-button": "awsui_action-button_1q84n_kln9a_109",
  "flash-type-success": "awsui_flash-type-success_1q84n_kln9a_866",
  "flash-type-error": "awsui_flash-type-error_1q84n_kln9a_870",
  "flash-type-warning": "awsui_flash-type-warning_1q84n_kln9a_874",
  "flash-type-info": "awsui_flash-type-info_1q84n_kln9a_878"
};
  