import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadTextFilter } from '../common/RadTextFilter'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadPieChart } from '../common/RadPieChart'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { useIsMobile } from '../hooks/useIsMobile'
import { formatDateTime } from '../common/utilities'

export function SurveyList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status') ?? 'active')
  const { data: surveys, count } = useGet(`/api/survey?search=${searchText}&status=${statusFilter}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const statusOptions = [
    { value: '', label: 'All surveys' },
    { value: 'active', label: 'Active surveys' },
    { value: 'ended', label: 'Ended surveys' }
  ]

  if (
    surveys != null
  ) {
    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/assessment/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Assessments
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <div style={{ minHeight: isMobile ? null : '100px' }}><RadLink fontSize='heading-l' href={`/assessment/${item.id}`}>{item.name}</RadLink></div>,
              sections: [
                {
                  id: 'client',
                  header: 'Organization',
                  content: item => <RadLink href={`/organization/${item.client.id}`}>{item.client.name}</RadLink>
                },
                {
                  id: 'questionSet',
                  header: 'Question Set',
                  content: item => <RadLink href={`/question-set/${item.questionSet.id}`}>{item.questionSet.name}</RadLink>
                },
                {
                  id: 'endAt',
                  header: 'End',
                  content: item => item.endAt != null ? formatDateTime(item.endAt) : '-'
                },
                {
                  id: 'pie-chart',
                  content: item =>
                    <RadPieChart
                      data={[
                        { title: 'Complete', value: parseInt(item.answerCount) },
                        { title: 'Not complete', value: (parseInt(item.participantCount) * parseInt(item.questionCount)) - parseInt(item.answerCount) }
                      ]}
                      segmentDescription={(datum, sum) => `${datum.value} questions, ${((datum.value / sum) * 100).toFixed(0)}%`}
                      i18nStrings={{
                        detailsValue: 'Value',
                        detailsPercentage: 'Percentage',
                        filterLabel: 'Filter displayed data',
                        filterPlaceholder: 'Filter data',
                        filterSelectedAriaLabel: 'selected',
                        detailPopoverDismissAriaLabel: 'Dismiss',
                        legendAriaLabel: 'Legend',
                        chartAriaRoleDescription: 'pie chart',
                        segmentAriaRoleDescription: 'segment'
                      }}
                      ariaDescription='Donut chart showing percentage of completeness.'
                      ariaLabel='Donut chart'
                      hideDescriptions
                      hideFilter
                      hideLegend
                      hideTitles
                      innerMetricDescription='complete'
                      innerMetricValue={`${(item.answerCount / (item.participantCount * item.questionCount) * 100).toFixed(0)}%`}
                      size='medium'
                      variant='donut'
                    />
                }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={surveys}
            variant='full-page'
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, xs: 8 } },
                  { colspan: { default: 12, xs: 4 } }
                ]}
              >
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search surveys'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.set('page', 1)
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadSelect
                  selectedOption={statusOptions.find(x => x.value === statusFilter)}
                  onChange={({ detail }) => {
                    if (detail.selectedOption.value === '') {
                      searchParams.delete('status')
                    } else {
                      searchParams.set('status', detail.selectedOption.value)
                    }
                    setStatusFilter(detail.selectedOption.value)
                    setSearchParams(searchParams)
                    setCurrentPageIndex(1)
                    searchParams.delete('page')
                  }}
                  options={statusOptions}
                  selectedAriaLabel='Selected'
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit' padding={{ top: 'xl' }}>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
