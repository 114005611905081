import React from 'react'
import ButtonDropdown from '@cloudscape-design/components/button-dropdown'

// https://cloudscape.design/components/button-dropdown

export function RadButtonDropdown ({ ...rest }) {
  return (
    <ButtonDropdown
      {...rest}
    />
  )
}
