import React from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadDivider } from '../common/RadDivider'

export function NotificationSettingsDetail () {
  const navigate = useNavigate()
  const { data: notificationSettings } = useGet('/api/notification-settings')
  const variables = [
    { name: '[SURVEY NAME]', value: 'Test Survey' },
    { name: '[SURVEY END DATE]', value: new Date().toLocaleDateString() },
    { name: '[CLIENT NAME]', value: 'Asemio' },
    { name: '[CLIENT LINK]', value: `https://oha.arrowhead.com/survey-results/${uuidv4()}` },
    { name: '[CLIENT LOGO]', value: 'https://asemio.com/wp-content/uploads/2020/09/Asemio_Blue-1.png' },
    { name: '[PARTICIPANT NAME]', value: 'John Doe' },
    { name: '[PARTICIPANT LINK]', value: `https://oha.arrowhead.com/survey/${uuidv4()}` },
    { name: '[COUNT OF PARTICIPANTS]', value: '500' },
    { name: '[COUNT OF NOT STARTED PARTICIPANTS]', value: '50' },
    { name: '[COUNT OF PARTIALLY COMPLETE PARTICIPANTS]', value: '350' },
    { name: '[COUNT OF COMPLETE PARTICIPANTS]', value: '100' },
    { name: '[PERCENT OF NOT STARTED PARTICIPANTS]', value: '10' },
    { name: '[PERCENT OF PARTIALLY COMPLETE PARTICIPANTS]', value: '70' },
    { name: '[PERCENT OF COMPLETE PARTICIPANTS]', value: '20' },
    { name: '[% COMPLETE]', value: '70' },
    { name: '[INCOMPLETE PARTICIPANT LIST]', value: 'John Doe (john.doe@example.com)<br />Jane Doe (jane.doe@example.com)' }
  ]

  function getPreview (text) {
    let results = text
    for (const variable of variables) {
      results = results.replaceAll(variable.name, variable.value)
    }
    return results
  }

  if (notificationSettings != null) {
    return (
      <RadAppLayout
        name='Notification Settings'
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
              </RadSpaceBetween>
            }
            description={variables.map(x => x.name).join(', ')}
          >
            Notification Settings
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader
                  variant='h2'
                  description='Sent to all participants within an hour after the survey start time.'
                >
                  Invitation
                </RadHeader>
              }
            >
              <RadSpaceBetween size='xxs'>
                <div>
                  <RadBox variant='awsui-key-label' float='left'>
                    Subject
                  </RadBox>
                  <RadBox float='left' padding={{ left: 's' }}>{getPreview(notificationSettings.invitationSubject) ?? '-'}</RadBox>
                </div>
                <RadDivider />
                <div>
                  <RadBox variant='awsui-key-label'>
                    Body
                  </RadBox>
                  <div dangerouslySetInnerHTML={{ __html: getPreview(notificationSettings.invitationBody) ?? '-' }} />
                </div>
              </RadSpaceBetween>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader
                  variant='h2'
                  description='Sent to all sponsors on active surveys daily at 8am.'
                >
                  Daily Status
                </RadHeader>
              }
            >
              <RadSpaceBetween size='xxs'>
                <div>
                  <RadBox variant='awsui-key-label' float='left'>
                    Subject
                  </RadBox>
                  <RadBox float='left' padding={{ left: 's' }}>{getPreview(notificationSettings.dailyStatusSubject) ?? '-'}</RadBox>
                </div>
                <RadDivider />
                <div>
                  <RadBox variant='awsui-key-label'>
                    Body
                  </RadBox>
                  <div dangerouslySetInnerHTML={{ __html: getPreview(notificationSettings.dailyStatusBody) ?? '-' }} />
                </div>
              </RadSpaceBetween>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader
                  variant='h2'
                  description='Sent to all participants who have not answered all non-optional questions on active surveys until the final day at 8am.'
                >
                  Daily Reminder
                </RadHeader>
              }
            >
              <RadSpaceBetween size='xxs'>
                <div>
                  <RadBox variant='awsui-key-label' float='left'>
                    Subject
                  </RadBox>
                  <RadBox float='left' padding={{ left: 's' }}>{getPreview(notificationSettings.dailyReminderSubject) ?? '-'}</RadBox>
                </div>
                <RadDivider />
                <div>
                  <RadBox variant='awsui-key-label'>
                    Body
                  </RadBox>
                  <div dangerouslySetInnerHTML={{ __html: getPreview(notificationSettings.dailyReminderBody) ?? '-' }} />
                </div>
              </RadSpaceBetween>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader
                  variant='h2'
                  description='Sent to all participants who have not answered all non-optional questions on surveys with less than 24 hours remaining daily at 8am.'
                >
                  Final Reminder
                </RadHeader>
              }
            >
              <RadSpaceBetween size='xxs'>
                <div>
                  <RadBox variant='awsui-key-label' float='left'>
                    Subject
                  </RadBox>
                  <RadBox float='left' padding={{ left: 's' }}>{getPreview(notificationSettings.finalReminderSubject) ?? '-'}</RadBox>
                </div>
                <RadDivider />
                <div>
                  <RadBox variant='awsui-key-label'>
                    Body
                  </RadBox>
                  <div dangerouslySetInnerHTML={{ __html: getPreview(notificationSettings.finalReminderBody) ?? '-' }} />
                </div>
              </RadSpaceBetween>
            </RadContainer>
            <RadContainer
              header={
                <RadHeader
                  variant='h2'
                  description='Sent to all sponsors within an hour after the survey end time.'
                >
                  Survey Complete
                </RadHeader>
              }
            >
              <RadSpaceBetween size='xxs'>
                <div>
                  <RadBox variant='awsui-key-label' float='left'>
                    Subject
                  </RadBox>
                  <RadBox float='left' padding={{ left: 's' }}>{getPreview(notificationSettings.surveyCompleteSubject) ?? '-'}</RadBox>
                </div>
                <RadDivider />
                <div>
                  <RadBox variant='awsui-key-label'>
                    Body
                  </RadBox>
                  <div dangerouslySetInnerHTML={{ __html: getPreview(notificationSettings.surveyCompleteBody) ?? '-' }} />
                </div>
              </RadSpaceBetween>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
