import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadRadioGroup } from '../common/RadRadioGroup'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function MultipleChoice ({ question, formValues, setFormValues, next }) {
  const participant = formValues
  const answers = participant.answers
  let answer = answers.find((x) => x.questionId === question.id)
  const items = question.answers.map((a) => ({ value: a.text, label: `${a.text}${a.value != null ? ' (' + a.value + ')' : ''}` }))
    .concat(question.hasOther ? [{ value: 'Other', label: 'Other' }] : [])

  return (
    <RadSpaceBetween>
      <RadBox variant='awsui-key-label'>
        {`${question.text} ${question.category != null ? ' - ' + question.category.name : ''} ${question.subcategory != null ? ' : ' + question.subcategory.name : ''}${question.isOptional || question.isInternal ? ' -' : ''}`}
        <i>{`${question.isOptional ? ' optional' : ''}${question.isInternal ? ' internal' : ''}`}</i>
      </RadBox>
      <RadFormField field={`question.${question.id}`}>
        {question.context != null && <RadBox color='text-body-secondary' padding={{ bottom: 'xs' }}>{question.context}</RadBox>}
        <RadRadioGroup
          onChange={({ detail }) => {
            if (answer == null) {
              answer = { surveyParticipantId: participant.id, questionId: question.id, text: null }
              answers.push(answer)
            }
            answer.text = detail.value
            if (answer.text !== 'Other') { answer.pleaseSpecify = null }
            setFormValues({ ...formValues, answers })
            if (answer.text !== 'Other' && next != null) { next() }
          }}
          value={answer?.text}
          items={items}
        />
      </RadFormField>
      {question.hasOther && answer?.text === 'Other' &&
        <RadFormField>
          <RadInput
            placeholder='Please specify'
            value={answer.pleaseSpecify}
            onChange={({ detail }) => {
              answer.pleaseSpecify = detail.value
              if (formValues != null) setFormValues({ ...formValues, answers })
            }}
          />
        </RadFormField>}
    </RadSpaceBetween>
  )
}
