import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadFileUpload } from '../common/RadFileUpload'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { stateList } from '../common/stateList'
import { toBase64 } from '../common/utilities'

export function ClientEdit () {
  const navigate = useNavigate()
  const { clientId } = useParams()
  const [formValues, setFormValues] = useState()
  const [files, setFiles] = useState([])
  const { data: client } = useGet(clientId ? `/api/client/${clientId}` : null)
  const { data: industryOptions } = useGet('/api/option/type?entity=industry')
  const { data: numberOfEmployeesOptions } = useGet('/api/option/type?entity=number_of_employees')
  const { data: typeOptions } = useGet('/api/option/type?entity=client')
  const stateListOptions = [...stateList]
  stateListOptions.unshift({ value: null, label: '-' })

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(client ?? defaultFormValues)
    if (client?.logo != null) {
      const file = new File(
        [],
        client.logo.path.split('/').pop(),
        {
          type: client.logo.type,
          lastModifiedDate: client.logo.updatedAt
        }
      )
      setFiles([file])
    }
  }, [client])

  const create = usePost('/api/client', formValues, (resp) => { navigate(`/organization/${resp.id}`) })
  const update = usePut(`/api/client/${clientId}`, formValues, (resp) => { navigate(`/organization/${clientId}`) })

  async function saveChanges () {
    if (clientId != null) { update() } else { create() }
  }

  async function cancel () {
    if (clientId != null) {
      navigate(`/organization/${clientId}`)
    } else {
      navigate('/organization')
    }
  }

  const parseValue = (str) => {
    if (str.includes('+')) return Infinity
    return parseInt(str.split('-')[0])
  }

  if (
    formValues != null &&
    industryOptions != null &&
    numberOfEmployeesOptions != null &&
    typeOptions != null
  ) {
    numberOfEmployeesOptions.sort((a, b) => parseValue(a.label) - parseValue(b.label))

    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Organization' : 'New Organization'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Type' field='typeId'>
                      <RadSelect
                        selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Description' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Industry' field='industryTypeId'>
                      <RadSelect
                        selectedOption={formValues.industryTypeId ? industryOptions.find(x => x.value === formValues.industryTypeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, industryTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, industryTypeId: null })
                          }
                        }}
                        options={industryOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Number of employees' field='numberOfEmployeesTypeId'>
                      <RadSelect
                        selectedOption={formValues.numberOfEmployeesTypeId ? numberOfEmployeesOptions.find(x => x.value === formValues.numberOfEmployeesTypeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, numberOfEmployeesTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, numberOfEmployeesTypeId: null })
                          }
                        }}
                        options={numberOfEmployeesOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Website' field='website'>
                      <RadInput
                        type='url'
                        inputMode='url'
                        placeholder='Enter website url'
                        value={formValues.website}
                        onChange={({ detail }) => setFormValues({ ...formValues, website: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Address
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <div>
                      <RadFormField label='Street' field='line1'>
                        <RadInput
                          placeholder='Enter line 1'
                          value={formValues.address?.line1}
                          onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line1: evt.detail.value } }) }}
                        />
                      </RadFormField>
                      <RadFormField field='line2'>
                        <RadInput
                          placeholder='Enter line 2'
                          value={formValues.address?.line2}
                          onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, line2: evt.detail.value } }) }}
                        />
                      </RadFormField>
                    </div>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, xs: 4 } },
                        { colspan: { default: 6, xs: 2 } },
                        { colspan: { default: 6, xs: 2 } }
                      ]}
                    >
                      <RadFormField label='City' field='city'>
                        <RadInput
                          placeholder='Enter city'
                          value={formValues.address?.city}
                          onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, city: evt.detail.value } }) }}
                        />
                      </RadFormField>
                      <RadFormField label='State' field='state'>
                        <RadSelect
                          filteringType='auto'
                          placeholder='Choose a state'
                          options={stateListOptions}
                          selectedOption={formValues.address?.state != null ? stateListOptions.find(x => x.value === formValues.address.state) : null}
                          onChange={({ detail }) => { setFormValues({ ...formValues, address: { ...formValues.address, state: detail.selectedOption.value } }) }}
                        />
                      </RadFormField>
                      <RadFormField label='ZIP' field='zip'>
                        <RadInput
                          placeholder='Enter ZIP code'
                          inputMode='numeric'
                          value={formValues.address?.zip}
                          onChange={(evt) => { setFormValues({ ...formValues, address: { ...formValues.address, zip: evt.detail.value } }) }}
                        />
                      </RadFormField>
                    </RadGrid>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Branding
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Corporate logo' field='logo'>
                      <RadFileUpload
                        onChange={async ({ detail }) => {
                          setFiles(detail.value)
                          if (detail.value[0] != null) {
                            const logo = {
                              name: detail.value[0].name,
                              type: detail.value[0].type
                            }
                            logo.data = await toBase64(detail.value[0])
                            console.log(logo)
                            setFormValues({ ...formValues, logo })
                          } else {
                            setFormValues({ ...formValues, logo: null })
                          }
                        }}
                        value={files}
                        i18nStrings={{
                          uploadButtonText: e =>
                            e ? 'Choose files' : 'Choose file',
                          dropzoneText: e =>
                            e
                              ? 'Drop files to upload'
                              : 'Drop file to upload',
                          removeFileAriaLabel: e =>
                          `Remove file ${e + 1}`,
                          limitShowFewer: 'Show fewer files',
                          limitShowMore: 'Show more files',
                          errorIconAriaLabel: 'Error'
                        }}
                        showFileThumbnail={false}
                        tokenLimit={1}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
