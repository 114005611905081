import React, { createContext, useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { firebase, auth, ui } from './firebase'
import { SignIn } from './SignIn'
import { RadTopNavigation } from './common/RadTopNavigation'
import { IsLoading } from './common/IsLoading'
import { RadNotFound } from './common/RadNotFound'
import { RadPermissionDenied } from './common/RadPermissionDenied'
import { RadConfirmationModal } from './common/RadConfirmationModal'
import { TakeSurvey } from './takeSurvey/TakeSurvey'
import { Dashboard } from './Dashboard'
import { HeatMapDetail } from './heatMap/HeatMapDetail'
import { HeatMapEdit } from './heatMap/HeatMapEdit'
import { HeatMapList } from './heatMap/HeatMapList'
import { NotificationSettingsDetail } from './notificationSettings/NotificationSettingsDetail'
import { NotificationSettingsEdit } from './notificationSettings/NotificationSettingsEdit'
import { ClientDetail } from './client/ClientDetail'
import { ClientEdit } from './client/ClientEdit'
import { ClientList } from './client/ClientList'
import { QuestionCategoryDetail } from './questionCategory/QuestionCategoryDetail'
import { QuestionCategoryEdit } from './questionCategory/QuestionCategoryEdit'
import { QuestionCategoryList } from './questionCategory/QuestionCategoryList'
import { QuestionSetDetail } from './questionSet/QuestionSetDetail'
import { QuestionSetEdit } from './questionSet/QuestionSetEdit'
import { QuestionSetList } from './questionSet/QuestionSetList'
import { RoleDetail } from './role/RoleDetail'
import { RoleEdit } from './role/RoleEdit'
import { RoleList } from './role/RoleList'
import { SurveyDetail } from './survey/SurveyDetail'
import { SurveyEdit } from './survey/SurveyEdit'
import { SurveyList } from './survey/SurveyList'
import { SurveyTypeDetail } from './surveyType/SurveyTypeDetail'
import { SurveyTypeEdit } from './surveyType/SurveyTypeEdit'
import { SurveyTypeList } from './surveyType/SurveyTypeList'
import { SurveyResults } from './survey/SurveyResults'
import { TypeEdit } from './type/TypeEdit'
import { TypeList } from './type/TypeList'
import { UserDetail } from './user/UserDetail'
import { UserEdit } from './user/UserEdit'
import { UserList } from './user/UserList'

import '@cloudscape-design/global-styles/index.css'
import './App.scss'

export const AppContext = createContext(null)

export function App () {
  const [user, setUser] = useState(undefined)
  const [loadingCount, setLoadingCount] = useState(1)
  const [notFound, setNotFound] = useState(false)
  const [permissionDenied, setPermissionDenied] = useState(null)
  const [error, setError] = useState()
  const [navigationOpen, setNavigationOpen] = useState(window.localStorage.getItem('navigationOpen') === 'true')
  const [reloadCounter, setReloadCounter] = useState(0)
  const [confirmModal, setConfirmModal] = useState({ visible: false })

  useEffect(() => {
    window.localStorage.setItem('navigationOpen', navigationOpen)
  }, [navigationOpen])

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      setUser(user)
      setLoadingCount(count => count - 1)
    })
    return () => unregisterAuthObserver()
  }, [])

  if (user === undefined) {
    return <IsLoading loadingCount={loadingCount} />
  }

  if (window.location.pathname.includes('survey-results')) {
    return (
      <BrowserRouter>
        <AppContext.Provider value={{
          user,
          loadingCount,
          setLoadingCount,
          notFound,
          setNotFound,
          permissionDenied,
          setPermissionDenied,
          reloadCounter,
          setReloadCounter,
          error,
          setError,
          navigationOpen,
          setNavigationOpen,
          confirmModal,
          setConfirmModal
        }}
        >
          <Routes>
            <Route path='/survey-results/:uuid' element={<SurveyResults />} />
          </Routes>
        </AppContext.Provider>
      </BrowserRouter>
    )
  }

  if (window.location.pathname.includes('survey')) {
    return (
      <BrowserRouter>
        <AppContext.Provider value={{
          user,
          loadingCount,
          setLoadingCount,
          notFound,
          setNotFound,
          permissionDenied,
          setPermissionDenied,
          reloadCounter,
          setReloadCounter,
          error,
          setError,
          navigationOpen,
          setNavigationOpen,
          confirmModal,
          setConfirmModal
        }}
        >
          <Routes>
            <Route path='/survey/:uuid' element={<TakeSurvey />} />
          </Routes>
        </AppContext.Provider>
      </BrowserRouter>
    )
  }

  // link to docs for options
  // https://firebase.google.com/docs/auth/web/firebaseui
  if (user === null) {
    ui.start('#firebaseui-auth-container', {
      signInFlow: 'popup',
      signInOptions: [
        firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
      ],
      callbacks: {
        signInFailure: function (error) {
          console.log(error)
        },
        signInSuccessWithAuthResult: function (result) {
          ui.reset()
          return false
        }
      }
    })
    ui.disableAutoSignIn()
    return <SignIn />
  }

  return (
    <BrowserRouter>
      <AppContext.Provider value={{
        user,
        loadingCount,
        setLoadingCount,
        notFound,
        setNotFound,
        permissionDenied,
        setPermissionDenied,
        reloadCounter,
        setReloadCounter,
        error,
        setError,
        navigationOpen,
        setNavigationOpen,
        confirmModal,
        setConfirmModal
      }}
      >
        <RadTopNavigation />
        <Routes>
          <Route path='/' element={<Dashboard />} />

          <Route path='/heat-map' element={<HeatMapList />} />
          <Route path='/heat-map/create' element={<HeatMapEdit />} />
          <Route path='/heat-map/:heatMapId' element={<HeatMapDetail />} />
          <Route path='/heat-map/:heatMapId/edit' element={<HeatMapEdit />} />

          <Route path='/notification-settings/edit' element={<NotificationSettingsEdit />} />
          <Route path='/notification-settings' element={<NotificationSettingsDetail />} />

          <Route path='/organization' element={<ClientList />} />
          <Route path='/organization/:clientId' element={<ClientDetail />} />
          <Route path='/organization/create' element={<ClientEdit />} />
          <Route path='/organization/:clientId/edit' element={<ClientEdit />} />

          <Route path='/question-category' element={<QuestionCategoryList />} />
          <Route path='/question-category/create' element={<QuestionCategoryEdit />} />
          <Route path='/question-category/:questionCategoryId' element={<QuestionCategoryDetail />} />
          <Route path='/question-category/:questionCategoryId/edit' element={<QuestionCategoryEdit />} />

          <Route path='/question-set' element={<QuestionSetList />} />
          <Route path='/question-set/create' element={<QuestionSetEdit />} />
          <Route path='/question-set/:questionSetId' element={<QuestionSetDetail />} />
          <Route path='/question-set/:questionSetId/edit' element={<QuestionSetEdit />} />

          <Route path='/assessment' element={<SurveyList />} />
          <Route path='/assessment/:surveyId' element={<SurveyDetail />} />
          <Route path='/assessment/create' element={<SurveyEdit />} />
          <Route path='/assessment/:surveyId/edit' element={<SurveyEdit />} />
          <Route path='/assessment/:surveyId/results' element={<SurveyResults />} />

          <Route path='/assessment-type' element={<SurveyTypeList />} />
          <Route path='/assessment-type/:surveyTypeId' element={<SurveyTypeDetail />} />
          <Route path='/assessment-type/create' element={<SurveyTypeEdit />} />
          <Route path='/assessment-type/:surveyTypeId/edit' element={<SurveyTypeEdit />} />

          <Route path='/survey/:uuid' element={<TakeSurvey />} />

          <Route path='/role' element={<RoleList />} />
          <Route path='/role/create' element={<RoleEdit />} />
          <Route path='/role/:roleId' element={<RoleDetail />} />
          <Route path='/role/:roleId/edit' element={<RoleEdit />} />

          <Route path='/options' element={<TypeList />} />
          <Route path='/type/:entity' element={<TypeEdit />} />

          <Route path='/user' element={<UserList />} />
          <Route path='/user/create' element={<UserEdit />} />
          <Route path='/user/:userId' element={<UserDetail />} />
          <Route path='/user/:userId/edit' element={<UserEdit />} />

          <Route path='*' element={<RadNotFound />} />
        </Routes>
        <IsLoading loadingCount={loadingCount} />
        {loadingCount === 0 && notFound && <RadNotFound />}
        {loadingCount === 0 && permissionDenied != null && <RadPermissionDenied message={permissionDenied} />}
        <RadConfirmationModal />
      </AppContext.Provider>
    </BrowserRouter>
  )
}
