
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_lm6vo_1rqvf_97",
  "layout-wrapper": "awsui_layout-wrapper_lm6vo_1rqvf_98",
  "root-no-scroll": "awsui_root-no-scroll_lm6vo_1rqvf_105",
  "layout": "awsui_layout_lm6vo_1rqvf_98",
  "layout-no-scroll": "awsui_layout-no-scroll_lm6vo_1rqvf_116",
  "layout-main": "awsui_layout-main_lm6vo_1rqvf_120",
  "layout-main-scrollable": "awsui_layout-main-scrollable_lm6vo_1rqvf_126",
  "unfocusable": "awsui_unfocusable_lm6vo_1rqvf_131",
  "breadcrumbs-desktop": "awsui_breadcrumbs-desktop_lm6vo_1rqvf_135",
  "content-header-wrapper": "awsui_content-header-wrapper_lm6vo_1rqvf_140",
  "content-wrapper": "awsui_content-wrapper_lm6vo_1rqvf_144",
  "content-overlapped": "awsui_content-overlapped_lm6vo_1rqvf_148",
  "content-extra-top-padding": "awsui_content-extra-top-padding_lm6vo_1rqvf_152"
};
  