import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import tinycolor from 'tinycolor2'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadContainer } from '../common/RadContainer'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { numberWithCommas } from '../common/utilities'

export function HeatMapDetail () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { heatMapId } = useParams()
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const { data: heatMap } = useGet(`/api/heat-map/${heatMapId}`)
  const { data: surveys, count } = useGet(`/api/survey?search=${searchText}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}&heatMapId=${heatMapId}  `)
  const confirmDelete = useConfirm('Delete question category?', 'Delete question category permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/heat-map/${heatMapId}`, () => { navigate('/heat-map') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  function background (score) {
    const range = heatMap.ranges.find(x => x.minValue <= score && x.maxValue >= score)
    const endColor = range != null ? range.color : 'grey'
    const startColor = tinycolor(endColor).lighten(20).toString()
    return range != null ? `linear-gradient(${startColor}, ${endColor})` : 'grey'
  }

  function getDescription (item) {
    const currentLevelNumber = heatMap.ranges.length - heatMap.ranges.findIndex(x => x === item)
    const currentLevel = item
    const graphicLevelIndicator =
          '<div style="display: flex;">' +
            '<div style="flex-direction: column; margin-top: 10px;">' +
            `${heatMap.ranges.map((x, i) => {
              console.log(x)
              let style
              if (x === currentLevel) {
                 style = `background: ${background(parseInt(x.minValue))}; height: 36px; width: 36px; margin-left: 8px; border-radius: 4px; box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.2)`
              } else {
                style = `background: ${background(parseInt(x.minValue))}; height: 32px; width: 32px; margin-left: 10px; border-radius: 4px;`
              }
              if (heatMap.ranges.length - i > currentLevelNumber) {
                return '<div style="display: flex;">' +
                  `<div style="${style}"></div>` +
                  '<div style="width: 20px; height: 32px; border-right: 2px solid black;"></div>' +
                  '</div>' +
                  '<div style="width: 62px; height: 8px; border-right: 2px solid black;"><div style="height: 8px; width: 26px; border-right: 1px dashed #BEBEBE"></div></div>'
              }
              if (heatMap.ranges.length - i === currentLevelNumber) {
                return '<div style="display: flex;">' +
                `<div style="${style}"></div>` +
                `<div style="width: ${x === currentLevel ? '18' : '16'}px; height: 16px; border-right: 2px solid black; border-bottom: 2px solid black;"></div>` +
                '</div>' +
                (heatMap.ranges.length - i > 1 ? '<div style="height: 8px; width: 26px; border-right: 1px dashed #BEBEBE"></div>' : '')
              }
              if (heatMap.ranges.length - i < currentLevelNumber) {
                return '<div style="display: flex;">' +
                  `<div style="${style}"></div>` +
                  '</div>' +
                  (heatMap.ranges.length - i > 1 ? '<div style="height: 8px; width: 26px; border-right: 1px dashed #BEBEBE"></div>' : '')
              }
              return ''
            }).join('')}` +
            '</div>' +
            '<div style="width: 20px; height: 10px; border-bottom: 2px solid black"></div>' +
            '<div>' +
              '<h1 style="background-color: black; color: white; display: inline;">' +
                `<span style="font-weight: normal; padding-left: 8px">Level ${currentLevelNumber}:</span>` +
                `<span style="font-weight: bold; padding: 2px 8px; text-transform: uppercase;">${currentLevel.name}</span>` +
              '</h1>' +
              `<div style="margin-top: 16px">${currentLevel.description}</div>` +
            '</div>' +
          '</div>'
    return graphicLevelIndicator
  }

  if (heatMap != null && surveys != null) {
    return (
      <RadAppLayout
        name={heatMap.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={heatMap.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={heatMap.description}
          >
            {heatMap.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <div>
                <RadBox variant='awsui-key-label'>
                  Ranges
                </RadBox>
                <div>{heatMap.ranges.length > 0
                  ? heatMap.ranges.map((x) => <div key={x.id}><span style={{ marginRight: '5px' }}>{x.name}</span> {x.minValue.toString().replace('.00', '')} to {x.maxValue.toString().replace('.00', '')} <span style={{ color: x.color, marginLeft: '5px' }}>{x.color}</span>{x.selectable ? ' - Selectable' : ''}</div>)
                  : '-'}
                </div>
              </div>
            </RadContainer>
            <RadCards
              cardDefinition={{
                header: item => <span style={{ color: item.color }}>{item.name}</span>,
                sections: [
                  {
                    id: 'scores',
                    header: 'Scores',
                    content: item => `${item.minValue} - ${item.maxValue}`
                  },
                  {
                    id: 'description',
                    content: item => <div dangerouslySetInnerHTML={{ __html: getDescription(item) }} />
                  },
                  {
                    id: 'selectable',
                    header: 'Selectable',
                    content: item => item.selectable ? 'Yes' : 'No'
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 1280, cards: 2 }
              ]}
              items={heatMap.ranges}
              variant='full-page'
              empty={
                <RadBox textAlign='center' color='inherit'>
                  No ranges added to this heat map.
                </RadBox>
            }
            />
            <RadTable
              header={
                <RadHeader
                  counter={'(' + numberWithCommas(count) + ')'}
                >
                  Assessments
                </RadHeader>
              }
              trackBy='id'
              filter={
                <RadGrid
                  gridDefinition={[
                    { colspan: { default: 12, xs: 9 } },
                    { colspan: { default: 12, xs: 3 } }
                  ]}
                >
                  <RadInput
                    placeholder='Search'
                    ariaLabel='Search'
                    onChange={({ detail }) => {
                      setCurrentPageIndex(1)
                      setSearchText(detail.value)
                    }}
                    value={searchText}
                    type='search'
                  />
                </RadGrid>
              }
              pagination={
                <RadPagination
                  currentPageIndex={currentPageIndex}
                  pagesCount={Math.ceil(count / pageLength)}
                  onChange={({ detail }) => {
                    searchParams.set('page', detail.currentPageIndex)
                    setSearchParams(searchParams)
                    setCurrentPageIndex(detail.currentPageIndex)
                  }}
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                  }}
                />
              }
              items={surveys}
              columnDefinitions={[
                {
                  id: 'name',
                  header: 'Name',
                  cell: e => <RadLink href={`/assessment/${e.id}`}>{e.name}</RadLink>
                }
              ]}
              empty={
                <RadBox textAlign='center' padding={{ top: 'l' }}>
                  <RadBox>
                    No matches found.
                  </RadBox>
                </RadBox>
              }
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}
