import React from 'react'
import { RadAppLayout } from './common/RadAppLayout'
import { RadHeader } from './common/RadHeader'

export function Dashboard () {
  return (
    <RadAppLayout
      contentHeader={
        <RadHeader
          variant='h1'
        >
          Dashboard
        </RadHeader>
      }
    />
  )
}
