import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { useIsMobile } from '../hooks/useIsMobile'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadExpandableSection } from '../common/RadExpandableSection'
import { RadDivider } from '../common/RadDivider'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadPagination } from '../common/RadPagination'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { numberWithCommas } from '../common/utilities'

export function SurveyEdit () {
  const pageLength = 20
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const [searchParams, setSearchParams] = useSearchParams()
  const { surveyId } = useParams()
  const [formValues, setFormValues] = useState()
  const [participantFilterText, setParticipantFilterText] = useState('')
  const [participantPageIndex, setParticipantPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const fileInput = useRef(null)
  const { data: participants } = useGet(surveyId ? `/api/survey/${surveyId}/participant?offset=0` : null)
  const { data: survey } = useGet(surveyId ? `/api/survey/${surveyId}` : null)
  const { data: ageOptions } = useGet('/api/option/type?entity=age')
  let { data: educationLevelOptions } = useGet('/api/option/type?entity=education_level')
  let { data: ethnicityOptions } = useGet('/api/option/type?entity=race_and_ethnicity')
  let { data: genderOptions } = useGet('/api/option/type?entity=gender')
  const { data: heatMaps } = useGet('/api/heat-map')
  const { data: clientOptions } = useGet('/api/option/client')
  const { data: positionLevelOptions } = useGet('/api/option/type?entity=position_level')
  const { data: questionSetOptions } = useGet('/api/option/questionSet')
  const { data: workEnvironmentOptions } = useGet('/api/option/type?entity=work_environment')
  const { data: yearsWithCompanyOptions } = useGet('/api/option/type?entity=years_with_company')
  const fields = [
    'firstName',
    'lastName',
    'email',
    'department',
    'subdepartment',
    'gender',
    'ethnicity',
    'age',
    'birthDate',
    'yearsWithCompany',
    'companyStartDate',
    'positionLevel',
    'location',
    'workEnvironment',
    'educationLevel'
  ]
  const ageRanges = (ageOptions ?? []).map((x) => {
    let min = 0
    let max = 0
    if (x.label.includes('-')) {
      min = parseInt(x.label.split('-')[0])
      max = parseInt(x.label.split('-')[1])
    }
    if (x.label.includes('+')) {
      min = parseInt(x.label.split('+')[0])
      max = 200
    }
    if (x.label.includes('<')) {
      min = 0
      max = parseInt(x.label.split('<')[1])
    }
    return { ...x, min, max }
  })
  const yearsWithCompanyRanges = (yearsWithCompanyOptions ?? []).map((x) => {
    let min = 0
    let max = 0
    if (x.label.includes('-')) {
      min = parseInt(x.label.split('-')[0])
      max = parseInt(x.label.split('-')[1])
    }
    if (x.label.includes('+')) {
      min = parseInt(x.label.split('+')[0])
      max = 200
    }
    if (x.label.includes('<')) {
      min = 0
      max = parseInt(x.label.split('<')[1])
    }
    return { ...x, min, max }
  })
  const selfIdentifyRequireOptions = [
    { label: '-', value: '0' },
    { label: 'Require', value: '2' }
  ]
  const selfIdentifyOptions = [
    { label: '-', value: '0' },
    { label: 'Allow', value: '1' },
    { label: 'Require', value: '2' }
  ]

  useEffect(() => {
    const defaultFormValues = {
      isPaused: false,
      isClosed: false,
      selfIdentifyAge: 0,
      selfIdentifyDepartment: 0,
      selfIdentifyEducationLevel: 0,
      selfIdentifyEthnicity: 0,
      selfIdentifyGender: 0,
      selfIdentifyLocation: 0,
      selfIdentifyPositionLevel: 0,
      selfIdentifySubdepartment: 0,
      selfIdentifyWorkEnvironment: 0,
      selfIdentifyYearsWithCompany: 0,
      departments: [],
      locations: [],
      managers: [],
      participants: [],
      sponsors: []
    }
    if (surveyId == null) {
      setFormValues(defaultFormValues)
    } else if (survey != null && participants != null) {
      setFormValues({ ...survey, participants })
    }
  }, [participants, survey, surveyId])

  const create = usePost('/api/survey', formValues, (resp) => { navigate(`/assessment/${resp.id}`) })
  const update = usePut(`/api/survey/${surveyId}`, formValues, (resp) => { navigate(`/assessment/${surveyId}`) })
  const confirmRemoveAllParticipants = useConfirm('Remove all participants?', 'Remove all participants? This action cannot be undone.', 'Remove', () => { setFormValues({ ...formValues, participants: [] }) })

  async function saveChanges () {
    if (surveyId != null) { update() } else { create() }
  }

  async function cancel () {
    if (surveyId != null) {
      navigate(`/assessment/${surveyId}`)
    } else {
      navigate('/survey')
    }
  }

  async function importFromCSV () {
    const file = fileInput.current.files[0]
    if (file == null) return

    const reader = new FileReader()
    reader.onload = function (e) {
      const csv = e.target.result
      const lines = csv.split('\n')
      const participants = formValues.participants.map((x) => ({ ...x }))
      const departments = formValues.departments.map((x) => ({ ...x }))
      const locations = formValues.locations.map((x) => ({ ...x }))
      for (const lineNumber in lines) {
        if (lineNumber > 0) {
          const line = lines[lineNumber]
          const values = line.split(',')
          const email = values[2] != null && values[2].trim() !== '' ? values[2].trim() : null
          let participant = participants.find((x) => x.email === email)
          if (email != null && participant == null) {
            participants.push({ uuid: uuidv4(), email, status: 'Not Started', watchedIntro: false, answers: [] })
            participant = participants.find((x) => x.email === email)
          }
          for (const index in fields) {
            const field = fields[index]
            const value = values[index] != null && values[index].trim() !== '' ? values[index].trim() : null
            if (value != null) {
              if (field === 'age') {
                participant.age = value
              } else if (field === 'yearsWithCompany') {
                participant.yearsWithCompany = value
              } else if (field === 'birthDate') {
                const years = moment().diff(moment(value), 'years')
                if (years > 0) {
                  participant.age = ageRanges.find((x) => x.min <= years && x.max >= years).label
                }
                participant.birthDate = moment(value).format('MM/DD/YYYY')
              } else if (field === 'companyStartDate') {
                const years = moment().diff(moment(value), 'years')
                if (years > 0) {
                  participant.yearsWithCompany = yearsWithCompanyRanges.find((x) => x.min <= years && x.max >= years).label
                }
                participant.companyStartDate = moment(value).format('MM/DD/YYYY')
              } else {
                participant[field] = value
                if (field === 'department' && departments.find((x) => x.name === value) == null) {
                  departments.push({ uuid: uuidv4(), name: value, subdepartments: [] })
                }
                if (field === 'subdepartment') {
                  const department = departments.find((x) => x.name === participant.department)
                  if (department.subdepartments.find((x) => x.name === value) == null) {
                    department.subdepartments.push({ uuid: uuidv4(), name: value })
                  }
                }
                if (field === 'location' && locations.find((x) => x.name === value) == null) {
                  locations.push({ uuid: uuidv4(), name: value })
                }
              }
            }
          }
        }
      }
      setFormValues({ ...formValues, participants, departments, locations })
      fileInput.current.value = null
    }
    reader.readAsText(file)
  }

  function toTitleCase (camelCase) {
    return camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .replace('Dob', 'Date of Birth')
      .replace('Ethnicity', 'Race and Ethnicity')
  }

  async function exportToCSV () {
    const csvContent = 'data:text/csv;charset=utf-8,' +
      fields
        .map((x) => toTitleCase(x))
        .join(',') + '\n' +
      formValues.participants.map((x) => {
        const values = []
        for (const index in fields) {
          const field = fields[index]
          values.push(x[field])
        }
        return values.join(',')
      }).join('\n')
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'participants.csv')
    document.body.appendChild(link)
    link.click()
  }

  if (
    formValues != null &&
    heatMaps != null &&
    ageOptions != null &&
    ethnicityOptions != null &&
    genderOptions != null &&
    clientOptions != null &&
    positionLevelOptions != null &&
    questionSetOptions != null &&
    workEnvironmentOptions != null &&
    yearsWithCompanyOptions != null
  ) {
    const filteredParticipants = formValues.participants
      .filter((x) =>
        participantFilterText === '' ||
        `${x.firstName}`.toLowerCase().includes(participantFilterText.toLowerCase()) ||
        `${x.lastName}`.toLowerCase().includes(participantFilterText.toLowerCase()) ||
        `${x.email}`.toLowerCase().includes(participantFilterText.toLowerCase())
      ) ?? []
    const heatMapOptions = heatMaps.map((x) => ({ value: x.id, label: x.name }))
    educationLevelOptions = educationLevelOptions
      .concat(formValues.participants
        .filter((x) => x.educationLevel != null && x.educationLevel.trim() !== '')
        .map((x) => ({ value: x.educationLevel, label: x.educationLevel }))
      )
      .reduce((acc, item) => {
        if (acc.some((accItem) => accItem.label === item.label) === false) acc.push(item)
        return acc
      }, [])
      .sort((a, b) => { if (a.label < b.label) { return -1 } if (a.label > b.label) { return 1 } return 0 })
    ethnicityOptions = ethnicityOptions
      .concat(formValues.participants
        .filter((x) => x.ethnicity != null && x.ethnicity.trim() !== '')
        .map((x) => ({ value: x.ethnicity, label: x.ethnicity }))
      )
      .reduce((acc, item) => {
        if (acc.some((accItem) => accItem.label === item.label) === false) acc.push(item)
        return acc
      }, [])
      .sort((a, b) => { if (a.label < b.label) { return -1 } if (a.label > b.label) { return 1 } return 0 })
    genderOptions = genderOptions
      .concat(formValues.participants
        .filter((x) => x.gender != null && x.gender.trim() !== '')
        .map((x) => ({ value: x.gender, label: x.gender }))
      )
      .reduce((acc, item) => {
        if (acc.some((accItem) => accItem.label === item.label) === false) acc.push(item)
        return acc
      }, [])
      .sort((a, b) => { if (a.label < b.label) { return -1 } if (a.label > b.label) { return 1 } return 0 })

    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Survey' : 'New Survey'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <input type='file' ref={fileInput} onChange={importFromCSV} style={{ display: 'none' }} />
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Organization *' field='clientId'>
                      <RadSelect
                        disabled={moment().isAfter(moment(formValues.startAt))}
                        selectedOption={formValues.clientId ? clientOptions.find(x => x.value === formValues.clientId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, clientId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, clientId: null })
                          }
                        }}
                        options={clientOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Question Set *' field='questionSetId'>
                      <RadSelect
                        disabled={moment().isAfter(moment(formValues.startAt))}
                        selectedOption={formValues.questionSetId ? questionSetOptions.find(x => x.value === formValues.questionSetId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, questionSetId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, questionSetId: null })
                          }
                        }}
                        options={questionSetOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Heat Map *' field='heatMapId'>
                      <RadSelect
                        selectedOption={formValues.heatMapId ? heatMapOptions.find(x => x.value === formValues.heatMapId) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, heatMapId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, heatMapId: null })
                          }
                        }}
                        options={heatMapOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadSpaceBetween direction='horizontal' size='l'>
                      <RadFormField label='Start date *' field='startDate'>
                        <RadInput
                          type='date'
                          value={formValues.startDate}
                          onChange={({ detail }) => setFormValues({ ...formValues, startDate: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Start time *' field='startTime'>
                        <RadInput
                          type='time'
                          value={formValues.startTime}
                          onChange={({ detail }) => setFormValues({ ...formValues, startTime: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='End date *' field='endDate'>
                        <RadInput
                          type='date'
                          value={formValues.endDate}
                          onChange={({ detail }) => setFormValues({ ...formValues, endDate: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='End time *' field='endTime'>
                        <RadInput
                          type='time'
                          value={formValues.endTime}
                          onChange={({ detail }) => setFormValues({ ...formValues, endTime: detail.value })}
                        />
                      </RadFormField>
                    </RadSpaceBetween>
                    <RadFormField label='Paused' field='paused'>
                      <RadCheckbox
                        checked={formValues.isPaused}
                        onChange={({ detail }) => { setFormValues({ ...formValues, isPaused: detail.checked, pausedMessage: detail.checked ? formValues.pausedMessage : null }) }}
                      />
                    </RadFormField>
                    {formValues.isPaused &&
                      <RadFormField label='Paused message' field='pausedMessage'>
                        <RadTextarea
                          placeholder='Enter paused message'
                          value={formValues.pausedMessage}
                          onChange={({ detail }) => setFormValues({ ...formValues, pausedMessage: detail.value })}
                        />
                      </RadFormField>}
                    <RadFormField label='Closed' field='Closed'>
                      <RadCheckbox
                        checked={formValues.isClosed}
                        onChange={({ detail }) => { setFormValues({ ...formValues, isClosed: detail.checked, ClosedMessage: detail.checked ? formValues.ClosedMessage : null }) }}
                      />
                    </RadFormField>
                    {formValues.isClosed &&
                      <RadFormField label='Closed message' field='closedMessage'>
                        <RadTextarea
                          placeholder='Enter closed message'
                          value={formValues.closedMessage}
                          onChange={({ detail }) => setFormValues({ ...formValues, closedMessage: detail.value })}
                        />
                      </RadFormField>}
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Self-Identification
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='xxs'>
                    <table>
                      <tbody>
                        <tr>
                          <td>Department</td>
                          <td style={{ minWidth: '150px' }}>
                            <RadFormField field='selfIdentifyDepartment'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyDepartment?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyDepartment: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyDepartment: null })
                                  }
                                }}
                                options={selfIdentifyRequireOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Subdepartment</td>
                          <td>
                            <RadFormField field='selfIdentifySubdepartment'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifySubdepartment?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifySubdepartment: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifySubdepartment: null })
                                  }
                                }}
                                options={selfIdentifyRequireOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Gender</td>
                          <td>
                            <RadFormField field='selfIdentifyGender'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyGender?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyGender: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyGender: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Race & Ethnicity</td>
                          <td>
                            <RadFormField field='selfIdentifyEthnicity'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyEthnicity?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyEthnicity: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyEthnicity: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Age</td>
                          <td>
                            <RadFormField field='selfIdentifyAge'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyAge?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyAge: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyAge: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Years with Company&nbsp;&nbsp;&nbsp;</td>
                          <td>
                            <RadFormField field='selfIdentifyYearsWithCompany'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyYearsWithCompany?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyYearsWithCompany: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyYearsWithCompany: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Position Level</td>
                          <td>
                            <RadFormField field='selfIdentifyPositionLevel'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyPositionLevel?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyPositionLevel: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyPositionLevel: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Location</td>
                          <td>
                            <RadFormField field='selfIdentifyLocation'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyLocation?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyLocation: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyLocation: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Work Environment</td>
                          <td>
                            <RadFormField field='selfIdentifyWorkEnvironment'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyWorkEnvironment?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyWorkEnvironment: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyWorkEnvironment: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                        <tr>
                          <td>Education Level</td>
                          <td>
                            <RadFormField field='selfIdentifyEducationLevel'>
                              <RadSelect
                                wrapText={false}
                                selectedOption={selfIdentifyOptions.find(x => x.value === formValues.selfIdentifyEducationLevel?.toString())}
                                onChange={({ detail }) => {
                                  if (detail.selectedOption.value !== '') {
                                    setFormValues({ ...formValues, selfIdentifyEducationLevel: parseInt(detail.selectedOption.value) })
                                  } else {
                                    setFormValues({ ...formValues, selfIdentifyEducationLevel: null })
                                  }
                                }}
                                options={selfIdentifyOptions}
                                selectedAriaLabel='Selected'
                                placeholder='Select option'
                              />
                            </RadFormField>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </RadSpaceBetween>
                </RadContainer>
                <RadExpandableSection
                  variant='container'
                  defaultExpanded
                  headerText='Departments'
                  headerCounter={'(' + formValues.departments.length + ')'}
                >
                  <RadSpaceBetween size='s'>
                    {formValues.departments.map((item) =>
                      <RadSpaceBetween size='xxs' key={`department-${item.id || item.uuid}`}>
                        {item !== formValues.departments[0] && <RadDivider />}
                        <DepartmentEditor
                          item={item}
                          formValues={formValues}
                          setFormValues={setFormValues}
                        />
                      </RadSpaceBetween>
                    )}
                    <RadButton
                      onClick={() => {
                        const departments = formValues.departments
                        departments.push({ uuid: uuidv4(), subdepartments: [] })
                        setFormValues({ ...formValues, departments })
                      }}
                    >
                      Add new department
                    </RadButton>
                  </RadSpaceBetween>
                </RadExpandableSection>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + formValues.locations.length + ')'}
                    >
                      Locations
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const locations = formValues.locations
                      locations.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, locations })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const locations = [...formValues.locations]
                      locations.splice(itemIndex, 1)
                      setFormValues({ ...formValues, locations })
                    }}
                    items={formValues.locations}
                    addButtonText='Add new location'
                    definition={[
                      {
                        label: 'Name *',
                        control: item => {
                          return (
                            <RadFormField field={`locations.${item.id ?? item.uuid}.name`}>
                              <RadInput
                                placeholder='Enter name'
                                ariaRequired
                                value={item.name}
                                onChange={({ detail }) => {
                                  const locations = formValues.locations
                                  item.name = detail.value
                                  setFormValues({ ...formValues, locations })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove location'
                    empty='No locations added to this survey.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + formValues.sponsors.length + ')'}
                    >
                      Sponsors
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const sponsors = formValues.sponsors
                      sponsors.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, sponsors })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const sponsors = [...formValues.sponsors]
                      sponsors.splice(itemIndex, 1)
                      setFormValues({ ...formValues, sponsors })
                    }}
                    items={formValues.sponsors}
                    addButtonText='Add new sponsor'
                    definition={[
                      {
                        label: 'First name *',
                        control: item => {
                          const sponsor = formValues.sponsors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`sponsors.${item.id ?? item.uuid}.firstName`}>
                              <RadInput
                                placeholder='Enter first name'
                                ariaRequired
                                value={item.firstName}
                                onChange={({ detail }) => {
                                  const sponsors = formValues.sponsors
                                  sponsor.firstName = detail.value
                                  setFormValues({ ...formValues, sponsors })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Last name *',
                        control: item => {
                          const sponsor = formValues.sponsors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`sponsors.${item.id ?? item.uuid}.lastName`}>
                              <RadInput
                                placeholder='Enter last name'
                                ariaRequired
                                value={item.lastName}
                                onChange={({ detail }) => {
                                  const sponsors = formValues.sponsors
                                  sponsor.lastName = detail.value
                                  setFormValues({ ...formValues, sponsors })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Email *',
                        control: item => {
                          const sponsor = formValues.sponsors.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? 0))
                          return (
                            <RadFormField field={`sponsors.${item.id ?? item.uuid}.email`}>
                              <RadInput
                                placeholder='Enter email'
                                ariaRequired
                                value={item.email}
                                onChange={({ detail }) => {
                                  const sponsors = formValues.sponsors
                                  sponsor.email = detail.value
                                  setFormValues({ ...formValues, sponsors })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      },
                      {
                        label: 'Participating',
                        control: item =>
                          formValues.participants.find((x) => x.email === item.email) != null
                            ? <RadBox margin={{ top: 'xs' }}>Yes</RadBox>
                            : <RadBox margin={{ top: 'xs' }}>No</RadBox>
                      }
                    ]}
                    removeButtonText='Remove sponsor'
                    empty='No sponsors added to this survey.'
                  />
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + formValues.managers.length + ')'}
                    >
                      Managers
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    {formValues.managers.map((item) =>
                      <ManagerEditor
                        key={`participant-${item.id},${item.uuid}`}
                        item={formValues.managers.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        isMobile={isMobile}
                      />)}
                    {formValues.managers.length === 0 && <RadBox padding={{ bottom: 'm' }} color='text-body-secondary'>No managers added to this survey.</RadBox>}
                    <RadButton
                      onClick={() => {
                        const managers = formValues.managers
                        managers.push({ uuid: uuidv4(), departments: [] })
                        setFormValues({ ...formValues, managers })
                        const lastPage = Math.ceil((formValues.managers.length + 1) / pageLength)
                        setParticipantPageIndex(lastPage)
                      }}
                    >
                      Add new manager
                    </RadButton>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + numberWithCommas(filteredParticipants.length) + ')'}
                      actions={
                        <RadSpaceBetween direction='horizontal' size='xs'>
                          <RadButton onClick={confirmRemoveAllParticipants} disabled={formValues.participants.length === 0}>Remove all participants</RadButton>
                          <RadButton onClick={() => fileInput.current.click()}>Import</RadButton>
                          <RadButton onClick={exportToCSV}>Export</RadButton>
                        </RadSpaceBetween>
                      }
                    >
                      Participants
                    </RadHeader>
                  }
                >
                  <RadGrid
                    gridDefinition={isMobile
                      ? [{ colspan: 12 }, { colspan: 12 }]
                      : [{ colspan: { default: 12, xxs: 4 } }, { colspan: { default: 12, xxs: 4 } }, { colspan: { default: 12, xxs: 4 } }]}
                  >
                    <RadInput
                      placeholder='Search'
                      ariaLabel='Search'
                      onChange={({ detail }) => {
                        setParticipantPageIndex(1)
                        setParticipantFilterText(detail.value)
                      }}
                      value={participantFilterText}
                      type='search'
                    />
                    {!isMobile && <div />}
                    <RadPagination
                      currentPageIndex={participantPageIndex}
                      pagesCount={Math.ceil(filteredParticipants.length / pageLength)}
                      onChange={({ detail }) => {
                        searchParams.set('page', detail.currentPageIndex)
                        setSearchParams(searchParams)
                        setParticipantPageIndex(detail.currentPageIndex)
                      }}
                      ariaLabels={{
                        nextPageLabel: 'Next page',
                        previousPageLabel: 'Previous page',
                        pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                      }}
                    />
                  </RadGrid>
                  <br />
                  {
                    filteredParticipants
                      .slice((participantPageIndex - 1) * pageLength, participantPageIndex * pageLength)
                      .map((item) =>
                        <ParticipantEditor
                          key={`participant-${item.id},${item.uuid}`}
                          item={formValues.participants.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))}
                          formValues={formValues}
                          setFormValues={setFormValues}
                          ageOptions={ageOptions}
                          educationLevelOptions={educationLevelOptions}
                          ethnicityOptions={ethnicityOptions}
                          genderOptions={genderOptions}
                          positionLevelOptions={positionLevelOptions}
                          workEnvironmentOptions={workEnvironmentOptions}
                          yearsWithCompanyOptions={yearsWithCompanyOptions}
                        />)
                  }
                  {formValues.participants.length === 0 && <RadBox padding={{ bottom: 'm' }} color='text-body-secondary'>No participants added to this survey.</RadBox>}
                  {filteredParticipants.length === 0 && formValues.participants.length > 0 && participantFilterText !== '' && <RadBox padding={{ bottom: 'm' }} color='text-body-secondary'>No matching participants.</RadBox>}
                  <RadSpaceBetween size='xs' direction='horizontal'>
                    <RadButton
                      onClick={() => {
                        const participants = formValues.participants
                        participants.push({ uuid: uuidv4(), status: 'Not Started', watchedIntro: false, answers: [] })
                        setFormValues({ ...formValues, participants })
                        const lastPage = Math.ceil((filteredParticipants.length + 1) / pageLength)
                        setParticipantPageIndex(lastPage)
                      }}
                    >
                      Add new participant
                    </RadButton>
                    <RadButton
                      onClick={async () => {
                        for (let i = 0; i < 1000; i++) {
                          const participants = formValues.participants
                          // const message = await createChatCompletion(
                          //   'Generate a completely random first name, last name and email and return as json with keys firstName, lastName and email. ' +
                          //   'This is used to generate random participants. Do not use John Doe as the name.'
                          // )
                          // const json = JSON.parse(message.content)
                          // const { firstName, lastName, email } = json
                          const firstName = 'first' + (participants.length + 1)
                          const lastName = 'last' + (participants.length + 1)
                          const email = 'email' + (participants.length + 1) + '@example.com'
                          participants.push({ uuid: uuidv4(), firstName, lastName, email, status: 'Not Started', watchedIntro: false, answers: [] })
                          setFormValues({ ...formValues, participants })
                          const lastPage = Math.ceil((filteredParticipants.length + 1) / pageLength)
                          setParticipantPageIndex(lastPage)
                        }
                      }}
                    >
                      Add 1k participants
                    </RadButton>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function ManagerEditor ({ item, formValues, setFormValues, isMobile }) {
  return (
    <RadSpaceBetween size='s'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } }
        ]}
      >
        <RadFormField label='First name *' field={`managers.${item.id ?? item.uuid}.firstName`}>
          <RadInput
            placeholder='Enter first name'
            ariaRequired
            value={item.firstName}
            onChange={({ detail }) => {
              const managers = formValues.managers
              item.firstName = detail.value
              setFormValues({ ...formValues, managers })
            }}
          />
        </RadFormField>
        <RadFormField label='Last name *' field={`managers.${item.id ?? item.uuid}.lastName`}>
          <RadInput
            placeholder='Enter last name'
            ariaRequired
            value={item.lastName}
            onChange={({ detail }) => {
              const managers = formValues.managers
              item.lastName = detail.value
              setFormValues({ ...formValues, managers })
            }}
          />
        </RadFormField>
        <RadFormField label='Email *' field={`managers.${item.id ?? item.uuid}.email`}>
          <RadInput
            placeholder='Enter email'
            ariaRequired
            value={item.email}
            onChange={({ detail }) => {
              const managers = formValues.managers
              item.email = detail.value
              setFormValues({ ...formValues, managers })
            }}
          />
        </RadFormField>

        <RadFormField label='Participating'>
          {
            formValues.participants.find((x) => x.email === item.email) != null
              ? <RadBox margin={{ top: 'xs' }}>Yes</RadBox>
              : <RadBox margin={{ top: 'xs' }}>No</RadBox>
          }
        </RadFormField>
        <RadBox padding={{ top: 'xxl' }}>
          <RadButton
            wrapText={false}
            onClick={() => {
              let managers = formValues.managers
              managers = managers.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, managers })
            }}
          >
            Remove manager
          </RadButton>
        </RadBox>
      </RadGrid>
      <RadBox padding={{ top: 's' }}>
        <RadBox fontWeight='bold' fontSize='heading-m' float='left'>Departments</RadBox>
        <RadBox float='left' fontSize='heading-m' color='text-status-inactive' padding={{ left: 'xxs' }}>{'(' + item.departments.length + ')'}</RadBox>
      </RadBox>
      {item.departments.length === 0 && <RadBox padding={{ bottom: 'm' }} color='text-body-secondary'>No departments added to this manager.</RadBox>}
      {item.departments.map((department) => {
        // const departmentOptions = [{ value: '', label: '-' }]
        //   .concat(
        //     formValues.departments
        //       .filter((x) => x.name != null && x.name.trim() !== '')
        //       .map((x) => ({ value: x.name, label: x.name }))
        //   )
        // const subdepartmentOptions = [{ value: '', label: '-' }]
        //   .concat(
        //     (formValues.departments.find((x) => x.name === department.name) ?? { subdepartments: [] })
        //       .subdepartments
        //       .map((x) => ({ value: x.name, label: x.name }))
        //   )
        const options = [{ value: '', label: '-' }]
          .concat(
            formValues.departments.flatMap((department) =>
              department.subdepartments.length > 0
                ? department.subdepartments.map((subdepartment) => `${department.name} - ${subdepartment.name}`)
                : department.name
            )
              .sort()
              .map((x) => ({ value: x, label: x }))
          )
        const filteredOptions = (options ?? []).filter((x) => !item.departments.map((y) => `${y.name}${y.subdepartment !== null ? ' - ' + y.subdepartment : ''}`).includes(x.value))

        return (
          <RadGrid
            key={`manager-${item.id ?? item.uuid}-department-${department.id ?? department.uuid}`}
            gridDefinition={[
              { colspan: { default: 12, xxs: 4 } },
              // { colspan: { default: 12, xxs: 4 } },
              { colspan: { default: 12, xxs: 4 } }
            ]}
          >
            <RadFormField label={isMobile || department === item.departments[0] ? 'Department *' : null} field={`managers.${item.id ?? item.uuid}.departments.${department.id ?? department.uuid}.name`}>
              <RadSelect
                selectedOption={options.find(x => x.label === `${department.name}${department.subdepartment != null ? ' - ' + department.subdepartment : ''}`) ?? { value: `${department.name ?? ''}${department.subdepartment != null ? ' - ' + department.subdepartment : ''}` ?? '', label: `${department.name ?? ''}${department.subdepartment != null ? ' - ' + department.subdepartment : ''}` ?? '-' }}
                onChange={({ detail }) => {
                  const managers = formValues.managers
                  if (detail.selectedOption.value !== '') {
                    department.name = detail.selectedOption.label.split(' - ')[0]
                    department.subdepartment = detail.selectedOption.label.split(' - ')[1]
                  } else {
                    department.name = null
                    department.subdepartment = null
                  }
                  setFormValues({ ...formValues, managers })
                }}
                options={filteredOptions}
                selectedAriaLabel='Selected'
                empty='No matches found'
              />
            </RadFormField>
            <RadBox padding={{ top: isMobile || department === item.departments[0] ? 'xl' : 'xxs' }}>
              <RadButton
                onClick={() => {
                  const managers = formValues.managers
                  item.departments = item.departments.filter((x) => x.id !== department.id || x.uuid !== department.uuid)
                  setFormValues({ ...formValues, managers })
                }}
              >
                Remove department
              </RadButton>
            </RadBox>
          </RadGrid>
        )
      })}
      <RadButton
        onClick={() => {
          const managers = formValues.managers
          item.departments.push({ uuid: uuidv4(), subdepartments: [] })
          setFormValues({ ...formValues, managers })
        }}
      >
        Add new department
      </RadButton>
      {(isMobile || item !== formValues.managers[formValues.managers.length - 1]) && <RadDivider />}
    </RadSpaceBetween>
  )
}

function ParticipantEditor ({
  item,
  formValues,
  setFormValues,
  ageOptions,
  educationLevelOptions,
  ethnicityOptions,
  genderOptions,
  positionLevelOptions,
  workEnvironmentOptions,
  yearsWithCompanyOptions
}) {
  const departmentOptions = formValues.departments
    .filter((x) => x.name != null && x.name.trim() !== '')
    .map((x) => ({ value: x.name, label: x.name }))
    .concat([{ value: '', label: '-' }])
  const locationOptions = formValues.locations
    .filter((x) => x.name != null && x.name.trim() !== '')
    .map((x) => ({ value: x.name, label: x.name }))
    .concat([{ value: '', label: '-' }])
  const subdepartmentOptions = (formValues.departments
    .find((x) => x.name === item.department) ?? { subdepartments: [] }).subdepartments
    .map((x) => ({ value: x.name, label: x.name }))
    .concat([{ value: '', label: '-' }])
  const ageRanges = (ageOptions ?? []).map((x) => {
    let min = 0
    let max = 0
    if (x.label.includes('-')) {
      min = parseInt(x.label.split('-')[0])
      max = parseInt(x.label.split('-')[1])
    }
    if (x.label.includes('+')) {
      min = parseInt(x.label.split('+')[0])
      max = 200
    }
    if (x.label.includes('<')) {
      min = 0
      max = parseInt(x.label.split('<')[1])
    }
    return { ...x, min, max }
  })
  const yearsWithCompanyRanges = (yearsWithCompanyOptions ?? []).map((x) => {
    let min = 0
    let max = 0
    if (x.label.includes('-')) {
      min = parseInt(x.label.split('-')[0])
      max = parseInt(x.label.split('-')[1])
    }
    if (x.label.includes('+')) {
      min = parseInt(x.label.split('+')[0])
      max = 200
    }
    if (x.label.includes('<')) {
      min = 0
      max = parseInt(x.label.split('<')[1])
    }
    return { ...x, min, max }
  })

  const parseValue = (str) => {
    if (str.includes('<')) return -1
    if (str.includes('+')) return Infinity
    return parseInt(str.split('-')[0])
  }

  return (
    <RadSpaceBetween size='s'>
      {/* <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 3 } },
          { colspan: { default: 12, xxs: 3 } }
        ]}
      > */}
      <RadSpaceBetween direction='horizontal' size='l'>
        <RadFormField label='First name *' field={`participants.${item.id ?? item.uuid}.firstName`}>
          <RadInput
            placeholder='Enter first name'
            ariaRequired
            value={item.firstName}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.firstName = detail.value
              setFormValues({ ...formValues, participants })
            }}
          />
        </RadFormField>
        <RadFormField label='Last name *' field={`participants.${item.id ?? item.uuid}.lastName`}>
          <RadInput
            placeholder='Enter last name'
            ariaRequired
            value={item.lastName}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.lastName = detail.value
              setFormValues({ ...formValues, participants })
            }}
          />
        </RadFormField>
        <RadFormField label='Email *' field={`participants.${item.id ?? item.uuid}.email`}>
          <RadInput
            placeholder='Enter email'
            ariaRequired
            value={item.email}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.email = detail.value
              setFormValues({ ...formValues, participants })
            }}
          />
        </RadFormField>
        <RadFormField label='Department' field={`participants.${item.id ?? item.uuid}.department`}>
          <RadSelect
            selectedOption={departmentOptions.find(x => x.label === item.department?.toString()) ?? { value: item.department ?? '', label: item.department ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.department = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.department = detail.selectedOption.label
              } else {
                item.department = null
              }
              item.subdepartment = null
              setFormValues({ ...formValues, participants })
            }}
            options={departmentOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Subdepartment' field={`participants.${item.id ?? item.uuid}.subdepartment`}>
          <RadSelect
            selectedOption={subdepartmentOptions.find(x => x.label === item.subdepartment?.toString()) ?? { value: item.subdepartment ?? '', label: item.subdepartment ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.subdepartment = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.subdepartment = detail.selectedOption.label
              } else {
                item.subdepartment = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={subdepartmentOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Gender' field={`participants.${item.id ?? item.uuid}.gender`}>
          <RadSelect
            selectedOption={genderOptions.find(x => x.label === item.gender?.toString()) ?? { value: item.gender ?? '', label: item.gender ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.gender = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.gender = detail.selectedOption.label
              } else {
                item.gender = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={genderOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Race and ethnicity' field={`participants.${item.id ?? item.uuid}.ethnicity`}>
          <RadSelect
            selectedOption={ethnicityOptions.find(x => x.label === item.ethnicity?.toString()) ?? { value: item.ethnicity ?? '', label: item.ethnicity ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.ethnicity = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.ethnicity = detail.selectedOption.label
              } else {
                item.ethnicity = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={ethnicityOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Birth date' field={`participants.${item.id ?? item.uuid}.birthDate`}>
          <RadInput
            type='date'
            value={item.birthDate}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.birthDate = detail.value
              const date = moment(detail.value)
              if (date.isValid()) {
                const years = moment().diff(moment(detail.value), 'years')
                item.age = ageRanges.find((x) => x.min <= years && x.max >= years)?.label
              }
              setFormValues({ ...formValues, participants })
            }}
          />
        </RadFormField>
        <RadFormField label='Age' field={`participants.${item.id ?? item.uuid}.age`}>
          <RadSelect
            selectedOption={ageOptions.find(x => x.label === item.age?.toString()) ?? { value: item.age ?? '', label: item.age ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.age = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.age = detail.selectedOption.label
              } else {
                item.age = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={ageOptions.sort((a, b) => parseValue(a.value) - parseValue(b.value))}
            selectedAriaLabel='Selected'
            empty='No matches found'
            disabled={item.birthDate != null && item.birthDate.trim() !== ''}
          />
        </RadFormField>
        <RadFormField label='Company start date' field={`participants.${item.id ?? item.uuid}.companyStartDate`}>
          <RadInput
            type='date'
            value={item.companyStartDate}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.companyStartDate = detail.value
              const date = moment(detail.value)
              if (date.isValid()) {
                const years = moment().diff(moment(detail.value), 'years')
                item.yearsWithCompany = yearsWithCompanyRanges.find((x) => x.min <= years && x.max >= years)?.label
              }
              setFormValues({ ...formValues, participants })
            }}
          />
        </RadFormField>
        <RadFormField label='Years with Company' field={`participants.${item.id ?? item.uuid}.yearsWithCompany`}>
          <RadSelect
            selectedOption={yearsWithCompanyOptions.find(x => x.label === item.yearsWithCompany?.toString()) ?? { value: item.yearsWithCompany ?? '', label: item.yearsWithCompany ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.yearsWithCompany = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.yearsWithCompany = detail.selectedOption.label
              } else {
                item.yearsWithCompany = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={yearsWithCompanyOptions.sort((a, b) => parseValue(a.value) - parseValue(b.value))}
            selectedAriaLabel='Selected'
            empty='No matches found'
            disabled={item.companyStartDate != null && item.companyStartDate.trim() !== ''}
          />
        </RadFormField>
        <RadFormField label='Position level' field={`participants.${item.id ?? item.uuid}.positionLevel`}>
          <RadSelect
            selectedOption={positionLevelOptions.find(x => x.label === item.positionLevel?.toString()) ?? { value: item.positionLevel ?? '', label: item.positionLevel ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.positionLevel = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.positionLevel = detail.selectedOption.label
              } else {
                item.positionLevel = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={positionLevelOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Location' field={`participants.${item.id ?? item.uuid}.location`}>
          <RadSelect
            selectedOption={locationOptions.find(x => x.label === item.location?.toString()) ?? { value: item.location ?? '', label: item.location ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.location = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.location = detail.selectedOption.label
              } else {
                item.location = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={locationOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Work environment' field={`participants.${item.id ?? item.uuid}.workEnvironment`}>
          <RadSelect
            selectedOption={workEnvironmentOptions.find(x => x.label === item.workEnvironment?.toString()) ?? { value: item.workEnvironment ?? '', label: item.workEnvironment ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.workEnvironment = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.workEnvironment = detail.selectedOption.label
              } else {
                item.workEnvironment = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={workEnvironmentOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        <RadFormField label='Education level' field={`participants.${item.id ?? item.uuid}.educationLevel`}>
          <RadSelect
            selectedOption={educationLevelOptions.find(x => x.label === item.educationLevel?.toString()) ?? { value: item.educationLevel ?? '', label: item.educationLevel ?? '-' }}
            onChange={({ detail }) => {
              const participants = formValues.participants
              item.educationLevel = detail.selectedOption.label
              if (detail.selectedOption.value !== '') {
                item.educationLevel = detail.selectedOption.label
              } else {
                item.educationLevel = null
              }
              setFormValues({ ...formValues, participants })
            }}
            options={educationLevelOptions}
            selectedAriaLabel='Selected'
            empty='No matches found'
          />
        </RadFormField>
        {/* </RadGrid> */}
      </RadSpaceBetween>
      <RadBox float='right'>
        <RadButton
          onClick={() => {
            let participants = formValues.participants
            participants = participants.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
            setFormValues({ ...formValues, participants })
          }}
        >
          Remove participant
        </RadButton>
      </RadBox>
      <RadDivider />
    </RadSpaceBetween>
  )
}

function DepartmentEditor ({ item, formValues, setFormValues }) {
  const isMobile = useIsMobile()

  return (
    <RadGrid
      gridDefinition={[
        { colspan: { default: 12, xxs: 3 } },
        { colspan: { default: 12, xxs: 6 } },
        { colspan: { default: 12, xxs: 3 } }
      ]}
    >
      {/* label={isMobile || item === department.subdepartments[0] ? 'Name *' : null}  */}
      <RadFormField label={isMobile || item === formValues.departments[0] ? 'Name *' : null} field={`departments.${item.id ?? item.uuid}.name`}>
        <RadInput
          placeholder='Enter name'
          ariaRequired
          value={item.name}
          onChange={({ detail }) => {
            const departments = formValues.departments
            item.name = detail.value
            setFormValues({ ...formValues, departments })
          }}
        />
      </RadFormField>
      <RadSpaceBetween direction='vertical' size='s'>
        {item.subdepartments.length === 0 &&
          <RadBox padding={{ top: 'xs' }} color='text-body-secondary'>No subdepartments added to this department.</RadBox>}
        {item.subdepartments.map((subdepartment) =>
          <SubdepartmentEditor
            key={`department-${item.id || item.uuid}-subdepartment-${subdepartment.id || subdepartment.uuid}`}
            item={subdepartment}
            formValues={formValues}
            setFormValues={setFormValues}
            department={item}
          />)}
        {isMobile && <RadDivider />}
        <RadButton
          onClick={() => {
            const departments = formValues.departments
            item.subdepartments.push({ uuid: uuidv4() })
            setFormValues({ ...formValues, departments })
          }}
        >
          Add new subdepartment
        </RadButton>
      </RadSpaceBetween>
      <RadBox padding={{ top: isMobile || item === formValues.departments[0] ? 'xl' : 'xxs' }}>
        <RadButton
          wrapText={false}
          onClick={() => {
            const departments = formValues.departments.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
            setFormValues({ ...formValues, departments })
          }}
        >
          Remove department
        </RadButton>
      </RadBox>
    </RadGrid>
  )
}

function SubdepartmentEditor ({ item, formValues, setFormValues, department }) {
  const isMobile = useIsMobile()

  return (
    // <RadSpaceBetween size='s'>
    <RadGrid
      gridDefinition={[
        { colspan: { default: 12, xxs: 7 } },
        { colspan: { default: 12, xxs: 3 } }
      ]}
    >
      <RadFormField label={isMobile || (department === formValues.departments[0] && item === department.subdepartments[0]) ? 'Subdepartments' : null} field={`departments.${department.id ?? department.uuid}.subdepartments.${item.id ?? item.uuid}.name`}>
        <RadInput
          placeholder='Enter name'
          ariaRequired
          value={item.name}
          onChange={({ detail }) => {
            const departments = formValues.departments
            item.name = detail.value
            setFormValues({ ...formValues, departments })
          }}
        />
      </RadFormField>
      <RadBox padding={{ top: !isMobile && department === formValues.departments[0] && item === department.subdepartments[0] ? 'xl' : 'xxs' }}>
        <RadButton
          wrapText={false}
          onClick={() => {
            const departments = formValues.departments
            department.subdepartments = department.subdepartments.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
            setFormValues({ ...formValues, departments })
          }}
        >
          Remove subdepartment
        </RadButton>
      </RadBox>
    </RadGrid>
    //   {isMobile && <RadDivider />}
    //  </RadSpaceBetween>
  )
}
