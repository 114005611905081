import React, { useContext } from 'react'
import Form from '@cloudscape-design/components/form'
import { AppContext } from './../App'

// https://cloudscape.design/components/form
export function RadForm ({ ...rest }) {
  const { error } = useContext(AppContext)

  return (
    <Form
      errorText={error?.message ?? error?.code}
      {...rest}
    />
  )
}
