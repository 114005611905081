import React, { useRef } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Stream } from '@cloudflare/stream-react'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function SurveyTypeDetail () {
  const streamRef = useRef(null)
  const streamRef2 = useRef(null)
  const navigate = useNavigate()
  const { surveyTypeId } = useParams()
  const { data: surveyType } = useGet(`/api/survey-type/${surveyTypeId}`)
  const confirmDelete = useConfirm('Delete Assessment Type?', 'Delete Assessment Type permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/survey-type/${surveyTypeId}`, () => { navigate('/assessment-type') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (surveyType != null) {
    return (
      <RadAppLayout
        name={surveyType.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={surveyType.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={surveyType.description}
          >
            {surveyType.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Name
                  </RadBox>
                  {surveyType.name}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Report Pages
                  </RadBox>
                  {surveyType.pages.length}
                </div>
              </RadColumnLayout>
            </RadContainer>
            {surveyType.surveyVideoLink != null &&
              <RadContainer
                header={
                  <RadHeader
                    variant='h2'
                  >
                    Survey Video
                  </RadHeader>
                }
              >
                <Stream
                  controls
                  streamRef={streamRef}
                  src={surveyType.surveyVideoLink}
                />
              </RadContainer>}
            {surveyType.heatMapVideoLink != null &&
              <RadContainer
                header={
                  <RadHeader
                    variant='h2'
                  >
                    Heat Map Video
                  </RadHeader>
                }
              >
                <Stream
                  controls
                  streamRef={streamRef2}
                  src={surveyType.heatMapVideoLink}
                />
              </RadContainer>}
          </RadSpaceBetween>
        }
      />
    )
  }
}
