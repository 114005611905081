import React, { useContext } from 'react'
import { AppContext } from './../App'
import { useLocation } from 'react-router-dom'
import { useIsMobile } from '../hooks/useIsMobile'
import SideNavigation from '@cloudscape-design/components/side-navigation'

// https://cloudscape.design/components/side-navigation
export function RadSideNavigation ({ setNavigationOpen }) {
  const { setError } = useContext(AppContext)
  const location = useLocation()
  const isMobile = useIsMobile()
  const rootHref = () => {
    let path = location.pathname
    let last = path.split('/').pop()
    while (!isNaN(parseInt(last, 10)) || last === 'edit') {
      path = path.replace(`/${last}`, '')
      last = path.split('/').pop()
    }
    return path
  }
  const [activeHref, setActiveHref] = React.useState(rootHref)

  return (
    <SideNavigation
      activeHref={activeHref}
      onFollow={(event) => {
        if (isMobile) setNavigationOpen(false)
        setError(null)
        setActiveHref(event.detail.href)
      }}
      items={[
        { type: 'link', text: 'Dashboard', href: '/' },
        { type: 'link', text: 'Organizations', href: '/organization' },
        { type: 'link', text: 'Assessments', href: '/assessment' },
        { type: 'divider' },
        {
          type: 'section',
          text: 'Assessment Management',
          items: [
            { type: 'link', text: 'Assessment Types', href: '/assessment-type' },
            { type: 'link', text: 'Question Sets', href: '/question-set' },
            { type: 'link', text: 'Question Categories', href: '/question-category' },
            { type: 'link', text: 'Attributes & Category Options', href: '/options' },
            { type: 'link', text: 'Notification Settings', href: '/notification-settings' },
            { type: 'link', text: 'Heat Maps', href: '/heat-map' }
          ]
        },
        {
          type: 'section',
          text: 'Settings',
          items: [
            { type: 'link', text: 'Users', href: '/user' },
            { type: 'link', text: 'Roles', href: '/role' }
          ]
        }
      ]}
    />
  )
}
