import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'

import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadTextarea } from '../common/RadTextarea'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function NotificationSettingsEdit () {
  const navigate = useNavigate()
  const [formValues, setFormValues] = useState()
  const { data: notificationSettings } = useGet('/api/notification-settings')

  useEffect(() => {
    const defaultFormValues = { }
    setFormValues(notificationSettings ?? defaultFormValues)
  }, [notificationSettings])

  const update = usePut('/api/notification-settings', formValues, (resp) => { navigate('/notification-settings') })

  async function saveChanges () {
    update()
  }

  async function cancel () {
    navigate('/notification-settings')
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name='Notification Settings'
        contentHeader={
          <RadHeader variant='h1'>
            Edit Notification Settings
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Invitation
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Subject *' field='invitationSubject'>
                      <RadInput
                        onChange={({ detail }) => setFormValues({ ...formValues, invitationSubject: detail.value })}
                        value={formValues.invitationSubject}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                    <RadFormField label='Body *' field='invitationBody'>
                      <RadTextarea
                        onChange={({ detail }) => setFormValues({ ...formValues, invitationBody: detail.value })}
                        value={formValues.invitationBody}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Daily Status
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Subject *' field='dailyStatusSubject'>
                      <RadInput
                        onChange={({ detail }) => setFormValues({ ...formValues, dailyStatusSubject: detail.value })}
                        value={formValues.dailyStatusSubject}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                    <RadFormField label='Body *' field='dailyStatusBody'>
                      <RadTextarea
                        onChange={({ detail }) => setFormValues({ ...formValues, dailyStatusBody: detail.value })}
                        value={formValues.dailyStatusBody}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Daily Reminder
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Subject *' field='dailyReminderSubject'>
                      <RadInput
                        onChange={({ detail }) => setFormValues({ ...formValues, dailyReminderSubject: detail.value })}
                        value={formValues.dailyReminderSubject}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                    <RadFormField label='Body *' field='dailyReminderBody'>
                      <RadTextarea
                        onChange={({ detail }) => setFormValues({ ...formValues, dailyReminderBody: detail.value })}
                        value={formValues.dailyReminderBody}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Final Reminder
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Subject *' field='finalReminderSubject'>
                      <RadInput
                        onChange={({ detail }) => setFormValues({ ...formValues, finalReminderSubject: detail.value })}
                        value={formValues.finalReminderSubject}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                    <RadFormField label='Body *' field='finalReminderBody'>
                      <RadTextarea
                        onChange={({ detail }) => setFormValues({ ...formValues, finalReminderBody: detail.value })}
                        value={formValues.finalReminderBody}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Survey Complete
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Subject *' field='surveyCompleteSubject'>
                      <RadInput
                        onChange={({ detail }) => setFormValues({ ...formValues, surveyCompleteSubject: detail.value })}
                        value={formValues.surveyCompleteSubject}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                    <RadFormField label='Body *' field='surveyCompleteBody'>
                      <RadTextarea
                        onChange={({ detail }) => setFormValues({ ...formValues, surveyCompleteBody: detail.value })}
                        value={formValues.surveyCompleteBody}
                        placeholder='Enter template'
                        rows={6}
                        ariaRequired
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
