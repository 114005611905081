import React, { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import GaugeChart from 'react-gauge-chart'
import { Stream } from '@cloudflare/stream-react'
import * as htmlToImage from 'html-to-image'
import tinycolor from 'tinycolor2'
import { useGet } from '../hooks/useGet'
import { usePost } from '../hooks/usePost'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadGrid } from '../common/RadGrid'
import { RadIcon } from '../common/RadIcon'
import { RadModal } from '../common/RadModal'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate } from '../common/utilities'
import './SurveyResults.scss'

export function SurveyResults () {
  const minParticipantCount = 3
  const streamRef = useRef(null)
  const streamRef2 = useRef(null)
  const { surveyId } = useParams()
  const { uuid } = useParams()
  const [reportLoading, setReportLoading] = useState(false)
  const [showIntroVideo, setShowIntroVideo] = useState(false)
  const [selectedSubcategory, setSelectedSubcategory] = useState()
  const [heatMapDescription, setHeatMapDescription] = useState([])
  const [showFilters, setShowFilters] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const defaultParticipantFilters = {
    department: null,
    gender: null,
    ethnicity: null,
    age: null,
    yearsWithCompany: null,
    positionLevel: null,
    location: null,
    workEnvironment: null,
    educationLevel: null
  }
  const [participantFilters, setParticipantFilters] = useState(defaultParticipantFilters)
  const resultsParams = Object.keys(participantFilters).map(key => `${key}=${participantFilters[key] ?? ''}`).join('&')
  const { data: results } = useGet(uuid != null ? `/api/survey/results/${uuid}?${resultsParams}` : surveyId != null ? `/api/survey/${surveyId}/results?${resultsParams}` : null, true)
  const updateVideoWatched = usePost(`/api/survey/${uuid}/set-video-watched/${results?.sponsor?.uuid || results?.manager?.uuid}`, {})

  useEffect(() => {
    const user = results?.sponsor || results?.manager
    if (user != null && !user.watchedIntro) {
      setShowIntroVideo(true)
    }
  }, [results])

  useEffect(() => {
    const fetch = async () => {
      if (selectedSubcategory == null) return
      const heatMapDescription = selectedSubcategory.heatMapDescription
      const description0 = await replaceVariables(heatMapDescription.split('[VIDEO]')[0], selectedSubcategory.score)
      const description1 = await replaceVariables(heatMapDescription.split('[VIDEO]')[1], selectedSubcategory.score)
      setHeatMapDescription([description0, description1])
      if (selectable(selectedSubcategory.score) === true) {
        setShowModal(true)
      }
    }
    fetch()
  }, [selectedSubcategory])

  function background (score) {
    const range = results.survey.heatMap.ranges.find(x => x.minValue <= parseInt(score) && x.maxValue >= parseInt(score))
    const endColor = range != null ? range.color : 'grey'
    const startColor = tinycolor(endColor).lighten(20).toString()
    return range != null ? `linear-gradient(${startColor}, ${endColor})` : 'grey'
  }

  function selectable (score) {
    return results.survey.heatMap.ranges.find(x => x.minValue <= parseInt(score) && x.maxValue >= parseInt(score))?.selectable
  }

  async function getElement (elementId) {
    return new Promise((resolve, reject) => {
      htmlToImage.toPng(document.getElementById(elementId))
        .then(function (dataUrl) {
          resolve(dataUrl)
        })
        .catch(function (error) {
          reject(error)
        })
    })
  }

  async function replaceVariables (html, score) {
    try {
      if (results == null || html == null) return html
      let output = html
        .replaceAll('[SURVEY END DATE]', results.survey.endAt != null ? formatDate(results.survey.endAt) : 'NO END DATE')
        .replaceAll('[CLIENT NAME]', results.survey.client.name)
        .replaceAll('[CLIENT LOGO]', results.survey.client.logo != null ? `<img src="${window.location.origin}/api/storage/${results.survey.client.logo?.path ?? ''}" style="width: 100%;" />` : '')
        .replaceAll('[COUNT OF PARTICIPANTS]', results.participantCount)
        .replaceAll('[COUNT OF COMPLETIONS]', results.completeCount)
        .replaceAll('[COUNT OF QUESTIONS]', results.questionCount)
        .replaceAll('[LEVEL COUNT]', results.survey.heatMap.ranges.length)

      if (output.includes('[RESULTS HEATMAP]')) {
        const dataUrl = await getElement('map')
        output = output.replaceAll('[RESULTS HEATMAP]', `<img src="${dataUrl}" style="width: 100%;" />`)
      }

      if (output.includes('[RESULTS GAUGE]')) {
        const dataUrl = await getElement('gauge-chart1')
        output = output.replaceAll('[RESULTS GAUGE]', `<img src="${dataUrl}" style="width: 100%;" />`)
      }

      if (output.includes('[RECOMMENDATIONS]')) {
        let recommendations = ''
        for (const category of results.categories) {
          for (const subcategory of category.subcategories) {
            if (subcategory.range?.selectable) {
              recommendations +=
                '<div>' +
                `<h2>${category.name} - ${subcategory.name}</h2>` +
                `<div>${subcategory.reportDescription}</div>` +
                '</div>'
            }
          }
        }
        output = output.replaceAll('[RECOMMENDATIONS]', recommendations)
      }

      if (output.includes('[OPEN RESPONSE ANSWERS]')) {
        let answers = ''
        for (const row of results.openResponseAnswers) {
          answers +=
            '<div>' +
            `<h2>${row.question}</h2>` +
            row.answers.map(x => `<p>"${x}"</p>`).join('') +
            '</div>'
        }
        output = output.replaceAll('[OPEN RESPONSE ANSWERS]', answers)
      }

      if (output.includes('[LEVEL]')) {
        const level =
          '<div style="width: 100%; height: 100%; display: flex; overflow: auto;">' +
          `${results.survey.heatMap.ranges.map((x, i) =>
            '<div style="flex: 1 1 0px; text-align: center;">' +
            `<div style="padding-bottom: 8px; font-weight: bold; line-height: 1.25;">Level ${i + 1}<br />${x.name}</div>` +
            `<div style="background-color: ${x.color}; border: 1px solid black; height: 100%; width: 100%;"></div>` +
            '</div>'
          ).join('')}` +
          '</div>'
        output = output.replaceAll('[LEVEL]', level)
      }

      if (output.includes('[GRAPHIC LEVEL INDICATOR]')) {
        const currentLevelNumber = results.survey.heatMap.ranges.length - results.survey.heatMap.ranges.findIndex(x => x.minValue <= score && x.maxValue >= score)
        const currentLevel = results.survey.heatMap.ranges.find(x => x.minValue <= score && x.maxValue >= score)
        const graphicLevelIndicator =
          '<div style="display: flex;">' +
            '<div style="flex-direction: column; margin-top: 10px;">' +
            `${results.survey.heatMap.ranges.map((x, i) => {
              let style
              if (x === currentLevel) {
                 style = `background: ${background(parseInt(x.minValue))}; height: 36px; width: 36px; margin-left: 8px; border-radius: 4px; box-shadow: -2px 4px 4px rgba(0, 0, 0, 0.2)`
              } else {
                style = `background: ${background(parseInt(x.minValue))}; height: 32px; width: 32px; margin-left: 10px; border-radius: 4px;`
              }
              if (results.survey.heatMap.ranges.length - i > currentLevelNumber) {
                return '<div style="display: flex;">' +
                  `<div style="${style}"></div>` +
                  '<div style="width: 20px; height: 32px; border-right: 2px solid black;"></div>' +
                  '</div>' +
                  '<div style="width: 62px; height: 8px; border-right: 2px solid black;"><div style="height: 8px; width: 26px; border-right: 1px dashed #BEBEBE"></div></div>'
              }
              if (results.survey.heatMap.ranges.length - i === currentLevelNumber) {
                return '<div style="display: flex;">' +
                `<div style="${style}"></div>` +
                `<div style="width: ${x === currentLevel ? '18' : '16'}px; height: 16px; border-right: 2px solid black; border-bottom: 2px solid black;"></div>` +
                '</div>' +
                (results.survey.heatMap.ranges.length - i > 1 ? '<div style="height: 8px; width: 26px; border-right: 1px dashed #BEBEBE"></div>' : '')
              }
              if (results.survey.heatMap.ranges.length - i < currentLevelNumber) {
                return '<div style="display: flex;">' +
                  `<div style="${style}"></div>` +
                  '</div>' +
                  (results.survey.heatMap.ranges.length - i > 1 ? '<div style="height: 8px; width: 26px; border-right: 1px dashed #BEBEBE"></div>' : '')
              }
              return ''
            }).join('')}` +
            '</div>' +
            '<div style="width: 20px; height: 10px; border-bottom: 2px solid black"></div>' +
            '<div>' +
              '<h1 style="background-color: black; color: white; display: inline;">' +
                `<span style="font-weight: normal; padding-left: 8px">Level ${currentLevelNumber}:</span>` +
                `<span style="font-weight: bold; padding: 2px 8px; text-transform: uppercase;">${currentLevel.name}</span>` +
              '</h1>' +
              `<div style="margin-top: 16px">${currentLevel.description}</div>` +
            '</div>' +
          '</div>'
        output = output.replaceAll('[GRAPHIC LEVEL INDICATOR]', graphicLevelIndicator)
      }

      return output
    } catch (error) {
      console.log(error)
      return ''
    }
  }

  async function downloadFullReport (url) {
    setReportLoading(true)
    const html = await replaceVariables(results.survey.questionSet.surveyType.report)
    const response = await fetch('/api/survey/pdf', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ html })
    })
    if (response.status === 200) {
      const blob = await response.blob()
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'results.pdf'
      document.body.appendChild(link)
      link.click()
    } else {
      console.log(response)
      throw new Error(`Error: ${response.status}`)
    }
    setReportLoading(false)
  }

  function cleanup (str) {
    return str
      .replace('ethnicitys', 'ethnicities')
      .replace('Years with companys', 'Years with company')
  }

  function toSentenceCase (str) {
    return cleanup(str.replace(/[A-Z]/g, letter => ` ${letter.toLowerCase()}`)).replace(/^\w/, c => c.toUpperCase())
  }

  if (results != null) {
    const rangeMin = Math.min(...results.survey.heatMap.ranges.map(r => r.minValue))
    const rangeMax = Math.max(...results.survey.heatMap.ranges.map(r => r.maxValue))
    const percent = (results.score - rangeMin) / (rangeMax - rangeMin)
    const scoreName = results.survey.heatMap.ranges.find(x => x.minValue <= results.score && x.maxValue >= results.score)?.name ?? ''
    const colors = results.survey.heatMap.ranges.map(x => x.color).reverse()
    const arcsLength = results.survey.heatMap.ranges.map(x => Math.abs(x.minValue - x.maxValue) / 150).reverse()

    return (
      <div className='results' style={{ maxWidth: results.categories.length > 6 ? 'auto' : '1280px' }}>
        <img src='/logo2.png' style={{ width: '300px', margin: '0 0 20px 20px' }} alt='OHA Logo' />
        <RadSpaceBetween direction='vertical' size='l'>
          <RadContainer>
            <RadGrid
              gridDefinition={[
                { colspan: { default: 12, s: 4 } },
                { colspan: { default: 12, s: 4 } },
                { colspan: { default: 12, s: 4 } }
              ]}
            >
              <RadBox>
                {results.survey.client.logo != null &&
                  <RadBox padding={{ bottom: 'l' }}>
                    <img src={`/api/storage/${results.survey.client.logo.path}`} style={{ width: '50%' }} alt='Client Logo' />
                  </RadBox>}
                <RadBox fontSize='heading-s' fontWeight='bold'>{results.survey.name}</RadBox>
                <RadBox padding={{ bottom: 'l' }} fontSize='heading-l'>RESULTS</RadBox>
                <RadSpaceBetween size='xs'>
                  <RadButton
                    onClick={() => downloadFullReport(results)}
                    className='black'
                    iconAlign='right'
                    iconName='download'
                    target='_blank'
                    ariaLabel='Download Full Report'
                    disabled={results.participantCount < minParticipantCount && (results.manager != null || results.sponsor != null)}
                    loading={reportLoading}
                  >
                    Download Full Report
                  </RadButton>
                  <RadButton
                    onClick={() => setShowIntroVideo(true)}
                    className='black'
                    ariaLabel='Show Introduction Video'
                  >
                    Show Introduction Video
                  </RadButton>
                </RadSpaceBetween>
              </RadBox>
              <div>
                {results.score != null &&
                 (results.participantCount >= minParticipantCount || (results.manager == null && results.sponsor == null)) &&
                   <RadBox textAlign='center'>
                     <GaugeChart
                       id='gauge-chart1'
                       animate={false}
                       arcsLength={arcsLength}
                       colors={colors}
                       textColor='#000716'
                       needleColor='#bbb'
                       needleBaseColor='#bbb'
                       formatTextValue={value => `${(results.score).toFixed(2)}`}
                       arcPadding={0.02}
                       percent={percent}
                     />
                     <RadBox fontSize='heading-s' fontWeight='bold' textAlign='center'>
                       Your Overall OHA Score is {scoreName}
                     </RadBox>
                     {results.survey.endAt &&
                       <RadBox color='text-body-inactive' textAlign='center' margin={{ top: 'xxs' }}>
                         Completed on {formatDate(results.survey.endAt, 'MMMM Do YYYY')}
                       </RadBox>}
                   </RadBox>}
              </div>
              <div>
                <RadBox textAlign='right'>
                  <RadSpaceBetween size='xxs'>
                    <RadButton
                      className='black'
                      iconName='filter'
                      onClick={() => setShowFilters(!showFilters)}
                      disabled={Object.keys(participantFilters).filter(x => results[x + 'Options'].length > 2).length === 0}
                    >
                      Filters
                    </RadButton>
                    {showFilters &&
                      <RadContainer className='filters'>
                        <RadSpaceBetween size='xxs'>
                          {Object.keys(participantFilters)
                            .map(key =>
                              results[key + 'Options'].length > 2 &&
                              (
                                <RadSelect
                                  key={key + '1'}
                                  selectedOption={results[key + 'Options'].find(x => x.value === (participantFilters[key] ?? ''))}
                                  onChange={({ detail }) => {
                                    setParticipantFilters({ ...participantFilters, [key]: detail.selectedOption.value })
                                    setShowFilters(false)
                                  }}
                                  options={results[key + 'Options']}
                                  selectedAriaLabel='Selected'
                                  empty='No matches found'
                                />
                              )
                            )}
                          <RadBox padding={{ top: 'xxs' }} textAlign='right'>
                            <RadButton
                              className='black'
                              onClick={() => {
                                setParticipantFilters(defaultParticipantFilters)
                                setShowFilters(false)
                              }}
                            >
                              Clear All
                            </RadButton>
                          </RadBox>
                        </RadSpaceBetween>
                      </RadContainer>}
                    <div>
                      {results.manager != null && (participantFilters.department == null || participantFilters.department === '') &&
                        <>
                          <RadBox variant='awsui-key-label'>Departments:</RadBox>
                          <RadBox padding={{ bottom: 'xxs' }}>
                            {results.departmentOptions.map(x => <div key={x.value}>{x.value}</div>)}
                          </RadBox>
                        </>}
                      {Object.keys(participantFilters)
                        .map(key =>
                          !showFilters && participantFilters[key] != null && participantFilters[key] !== '' &&
                            <RadBox key={key + '2'} variant='awsui-key-label'>{toSentenceCase(key)}: <RadBox display='inline'>{participantFilters[key]}</RadBox></RadBox>
                        )}
                    </div>
                    <RadBox padding={{ top: 'xxs' }} textAlign='right'>
                      {`${results.participantCount} participant(s)`}
                    </RadBox>
                  </RadSpaceBetween>
                </RadBox>
              </div>
            </RadGrid>

          </RadContainer>
          {results.participantCount < minParticipantCount && (results.manager != null || results.sponsor != null) &&
            <RadBox textAlign='center' padding={{ top: 'xl', bottom: 'xl', left: 'xl', right: 'xl' }} fontSize='heading-l'>
              The selected filters reduce the sample size below the allowable limit.
              <br />
              Reduce the applied filters in order to view the heatmap results
            </RadBox>}

          {results.categories.length > 0 && (results.participantCount >= minParticipantCount || (results.manager == null && results.sponsor == null)) &&
            <div id='map' style={{ minWidth: `${results.categories.length * 200}px` }}>
              <div>
                <div className='categories-container'>
                  {results.categories.map((category) => (
                    <div key={category.id} className='category'>
                      <div className='box'>
                        <div className='content' style={{ backgroundImage: background(category.score) }}>
                          <RadSpaceBetween size='xs'>
                            <div className='title'>{category.name}</div>
                            {category.icon != null && <img src={`/api/storage/${category.icon}`} style={{ height: '37px' }} alt='Icon' />}
                            {category.icon == null && <RadIcon size='big' name='status-warning' />}
                            <div className='progress'>
                              <div className='bar' style={{ width: category.score + '%' }} />
                            </div>
                            <span className='badge'>{category.score.toFixed(2)}</span>
                          </RadSpaceBetween>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='categories-arrow-container'>
                  {results.categories.map((category) => (
                    <div key={`${category.id}-arrow`}>
                      <img src='/polygon-4.png' alt='Arrow pointing down' />
                    </div>
                  ))}
                </div>
              </div>
              <div className='subcategories-container'>
                {results.categories.map((category, categoryIndex) => {
                  return (
                    <div key={category.id} className='subcategory'>
                      {category.subcategories.map((subcategory, subcategoryIndex) => {
                        return (
                          <div key={`subcategory-${subcategory.id}`}>
                            <div
                              className={selectable(subcategory.score) ? 'active button' : 'button'}
                              style={{
                                backgroundImage: (selectable(subcategory.score) ? 'url(\'/hangout_video.png\'), ' : '') + background(subcategory.score),
                                cursor: selectable(subcategory.score) ? 'pointer' : 'default'
                              }}
                              onClick={() => {
                                setSelectedSubcategory(subcategory)
                              }}
                            >
                              <RadSpaceBetween size='xs'>
                                <div className='title'>{subcategory.name}</div>
                                {subcategory.icon != null && <img src={`/api/storage/${subcategory.icon}`} style={{ height: '37px' }} alt={`${subcategory.name} Icon`} />}
                                {subcategory.icon == null && <RadIcon size='big' name='status-warning' />}
                                <div className='progress'>
                                  <div className='bar' style={{ width: subcategory.score + '%' }} />
                                </div>
                                <span className='badge'>{subcategory.score.toFixed(2)}</span>
                              </RadSpaceBetween>
                            </div>
                            {subcategoryIndex < category.subcategories.length - 1 &&
                              <div className='dashes' />}
                          </div>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>}
          {selectedSubcategory != null &&
            <RadModal
              size='large'
              onDismiss={() => {
                setShowModal(false)
                setSelectedSubcategory(null)
                streamRef?.current?.pause()
              }}
              visible={showModal}
              header={selectedSubcategory?.name}
            >
              <div dangerouslySetInnerHTML={{ __html: heatMapDescription[0] }} />
              {selectedSubcategory.videoLink != null && selectedSubcategory.heatMapDescription.includes('[VIDEO]') &&
                <RadBox padding={{ top: 'l' }}>
                  <Stream
                    streamRef={streamRef}
                    src={selectedSubcategory.videoLink}
                  />
                </RadBox>}
              <div dangerouslySetInnerHTML={{ __html: heatMapDescription[1] }} />
            </RadModal>}
          <RadModal
            size='large'
            onDismiss={() => {
              setShowIntroVideo(false)
              streamRef2.current.pause()
            }}
            visible={showIntroVideo}
            header='Introduction Video'
          >
            <Stream
              streamRef={streamRef2}
              src={results.survey.questionSet.surveyType.heatMapVideoLink}
              onEnded={() => updateVideoWatched()}
            />
          </RadModal>
        </RadSpaceBetween>
        {showFilters && <div className='scrim' onClick={() => setShowFilters(false)} />}
      </div>
    )
  }
}
