import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Stream } from '@cloudflare/stream-react'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function QuestionCategoryDetail () {
  const navigate = useNavigate()
  const { questionCategoryId } = useParams()
  const { data: questionCategory } = useGet(`/api/question-category/${questionCategoryId}`)
  const confirmDelete = useConfirm('Delete question category?', 'Delete question category permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/question-category/${questionCategoryId}`, () => { navigate('/question-category') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (questionCategory != null) {
    return (
      <RadAppLayout
        name={questionCategory.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={questionCategory.questionCount !== '0'}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={questionCategory.description}
          >
            {questionCategory.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Icon
                  </RadBox>
                  {questionCategory.icon != null ? <img style={{ maxHeight: '37px' }} src={`/api/storage/${questionCategory.icon.path}`} alt={questionCategory.name} /> : '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Assessment type
                  </RadBox>
                  {questionCategory.surveyType.name}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Disabled
                  </RadBox>
                  {questionCategory.isDisabled ? 'Yes' : 'No'}
                </div>
              </RadColumnLayout>
            </RadContainer>
            <RadBox variant='h2' padding={{ top: 'l' }}>
              Subcategories
              <RadBox
                variant='h2'
                color='text-body-secondary'
                display='inline'
                padding={{ left: 'xs' }}
                fontWeight='normal'
              >
                ({questionCategory.subcategories.length})
              </RadBox>
            </RadBox>
            <RadCards
              cardDefinition={{
                header: item => item.name,
                sections: [
                  {
                    id: 'icon',
                    header: 'Icon',
                    content: item => item.icon != null ? <img style={{ maxHeight: '37px' }} src={`/api/storage/${item.icon.path}`} alt={item.name} /> : <span style={{ display: 'block', height: '37px' }}>-</span>
                  },
                  {
                    id: 'videoLink',
                    content: item =>
                      item.videoLink != null
                        ? <Stream controls src={item.videoLink} width='100%' />
                        : 'No video'
                  },
                  {
                    id: 'isDisabled',
                    header: 'Disabled',
                    content: item => item.isDisabled ? 'Yes' : 'No'
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={questionCategory.subcategories}
              variant='full-page'
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}
