
    import './styles.scoped.css';
    export default {
  "root": "awsui_root_1fj9k_tjzk8_5",
  "navigation": "awsui_navigation_1fj9k_tjzk8_9",
  "navigation-toggle": "awsui_navigation-toggle_1fj9k_tjzk8_13",
  "navigation-close": "awsui_navigation-close_1fj9k_tjzk8_17",
  "content": "awsui_content_1fj9k_tjzk8_21",
  "notifications": "awsui_notifications_1fj9k_tjzk8_25",
  "breadcrumbs": "awsui_breadcrumbs_1fj9k_tjzk8_29",
  "tools": "awsui_tools_1fj9k_tjzk8_33",
  "tools-close": "awsui_tools-close_1fj9k_tjzk8_37",
  "tools-toggle": "awsui_tools-toggle_1fj9k_tjzk8_41",
  "drawer-closed": "awsui_drawer-closed_1fj9k_tjzk8_45",
  "mobile-bar": "awsui_mobile-bar_1fj9k_tjzk8_49",
  "disable-body-scroll-root": "awsui_disable-body-scroll-root_1fj9k_tjzk8_53",
  "drawers-desktop-triggers-container": "awsui_drawers-desktop-triggers-container_1fj9k_tjzk8_57",
  "drawers-mobile-triggers-container": "awsui_drawers-mobile-triggers-container_1fj9k_tjzk8_61",
  "drawers-trigger": "awsui_drawers-trigger_1fj9k_tjzk8_65",
  "active-drawer": "awsui_active-drawer_1fj9k_tjzk8_69",
  "active-drawer-close-button": "awsui_active-drawer-close-button_1fj9k_tjzk8_73"
};
  