import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function SurveyTypeEdit () {
  const navigate = useNavigate()
  const { surveyTypeId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: surveyType } = useGet(surveyTypeId ? `/api/survey-type/${surveyTypeId}` : null)
  const variables = [
    '[CLIENT LOGO]',
    '[CLIENT NAME]',
    '[COUNT OF COMPLETIONS]',
    '[COUNT OF PARTICIPANTS]',
    '[COUNT OF QUESTIONS]',
    '[GRAPHIC LEVEL INDICATOR]',
    '[LEVEL COUNT]',
    '[LEVEL]',
    '[OPEN RESPONSE ANSWERS]',
    '[RECOMMENDATIONS]',
    '[RESULTS HEATMAP]',
    '[SURVEY END DATE]'
  ]

  useEffect(() => {
    const defaultFormValues = { pages: [] }
    setFormValues(surveyType ?? defaultFormValues)
  }, [surveyType])

  const create = usePost('/api/survey-type', formValues, (resp) => { navigate(`/assessment-type/${resp.id}`) })
  const update = usePut(`/api/survey-type/${surveyTypeId}`, formValues, (resp) => { navigate(`/assessment-type/${surveyTypeId}`) })

  async function saveChanges () {
    if (surveyTypeId != null) { update() } else { create() }
  }

  async function cancel () {
    if (surveyTypeId != null) {
      navigate(`/assessment-type/${surveyTypeId}`)
    } else {
      navigate('/assessment-type')
    }
  }

  if (
    formValues != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Assessment Type' : 'New Assessment Type'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Survey Video Link' field='surveyVideoLink'>
                      <RadTextarea
                        rows={5}
                        placeholder='Enter survey video link'
                        ariaRequired
                        value={formValues.surveyVideoLink}
                        onChange={({ detail }) => setFormValues({ ...formValues, surveyVideoLink: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Heat Map Video Link' field='heatMapVideoLink'>
                      <RadTextarea
                        rows={5}
                        placeholder='Enter heat map video link'
                        ariaRequired
                        value={formValues.heatMapVideoLink}
                        onChange={({ detail }) => setFormValues({ ...formValues, heatMapVideoLink: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Report *' field='report'>
                      <RadTextarea
                        rows={12}
                        placeholder='Enter report'
                        ariaRequired
                        value={formValues.report}
                        onChange={({ detail }) => setFormValues({ ...formValues, report: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      description={`Variables: ${variables.join(', ')}`}
                    >
                      Report
                    </RadHeader>
                  }
                >
                  <RadAttributeEditor
                    onAddButtonClick={() => {
                      const pages = formValues.pages
                      pages.push({ uuid: uuidv4() })
                      setFormValues({ ...formValues, pages })
                    }}
                    onRemoveButtonClick={({
                      detail: { itemIndex }
                    }) => {
                      const pages = [...formValues.pages]
                      pages.splice(itemIndex, 1)
                      setFormValues({ ...formValues, pages })
                    }}
                    items={formValues.pages}
                    addButtonText='Add new page'
                    definition={[
                      {
                        label: 'Page',
                        control: item => {
                          return (
                            <RadFormField field={`pages.${item.id || item.uuid}.id`}>
                              <RadTextarea
                                rows={6}
                                placeholder='Enter html'
                                value={item.html}
                                onChange={({ detail }) => {
                                  item.html = detail.value
                                  const pages = formValues.pages
                                  setFormValues({ ...formValues, pages })
                                }}
                              />
                            </RadFormField>
                          )
                        }
                      }
                    ]}
                    removeButtonText='Remove page'
                    empty='No pages added to this assessment type.'
                  />
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
