import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { useIsMobile } from '../hooks/useIsMobile'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadPieChart } from '../common/RadPieChart'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDateTime } from '../common/utilities'

export function ClientDetail () {
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { clientId } = useParams()
  const { data: client } = useGet(`/api/client/${clientId}`, true)
  const confirmDelete = useConfirm('Delete organization?', 'Delete organization permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/client/${clientId}`, () => { navigate('/organization') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (client != null) {
    return (
      <RadAppLayout
        name={client.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={client.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={client.description}
          >
            {client.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={3} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Logo
                    </RadBox>
                    {client.logo != null && <img style={{ maxWidth: '50%' }} src={`/api/storage/${client.logo.path}`} alt={`${client.name} Logo`} />}
                    {client.logo == null && '-'}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Type
                    </RadBox>
                    {client.type?.name ?? '-'}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Industry
                    </RadBox>
                    {client.industry?.name ?? '-'}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Number of employees
                    </RadBox>
                    {client.numberOfEmployees?.name ?? '-'}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Address
                    </RadBox>
                    <div>{client.address?.line1}</div>
                    {client.address?.line2 && <div>{client.address?.line2}</div>}
                    <div>{client.address?.city} {client.address?.state} {client.address?.zip}</div>
                    {!client.addressId && '-'}
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Website
                    </RadBox>
                    <div>{client.website != null ? <RadLink href={client.website}>{client.website}</RadLink> : '-'}</div>
                  </div>
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>
            <RadCards
              cardDefinition={{
                header: item => <div style={{ minHeight: isMobile ? null : '100px' }}><RadLink fontSize='heading-l' href={`/assessment/${item.id}`}>{item.name}</RadLink></div>,
                sections: [
                  {
                    id: 'questionSet',
                    header: 'Question Set',
                    content: item => <RadLink href={`/question-set/${item.questionSet.id}`}>{item.questionSet.name}</RadLink>
                  },
                  {
                    id: 'endAt',
                    header: 'End',
                    content: item => item.endAt != null ? formatDateTime(item.endAt) : '-'
                  },
                  {
                    id: 'pie-chart',
                    content: item =>
                      <RadPieChart
                        data={[
                          { title: 'Complete', value: parseInt(item.answerCount) },
                          { title: 'Not complete', value: (parseInt(item.participantCount) * parseInt(item.questionCount)) - parseInt(item.answerCount) }
                        ]}
                        segmentDescription={(datum, sum) => `${datum.value} questions, ${((datum.value / sum) * 100).toFixed(0)}%`}
                        i18nStrings={{
                          detailsValue: 'Value',
                          detailsPercentage: 'Percentage',
                          filterLabel: 'Filter displayed data',
                          filterPlaceholder: 'Filter data',
                          filterSelectedAriaLabel: 'selected',
                          detailPopoverDismissAriaLabel: 'Dismiss',
                          legendAriaLabel: 'Legend',
                          chartAriaRoleDescription: 'pie chart',
                          segmentAriaRoleDescription: 'segment'
                        }}
                        ariaDescription='Donut chart showing percentage of completeness.'
                        ariaLabel='Donut chart'
                        hideDescriptions
                        hideFilter
                        hideLegend
                        hideTitles
                        innerMetricDescription='complete'
                        innerMetricValue={`${(item.answerCount / (item.participantCount * item.questionCount) * 100).toFixed(0)}%`}
                        size='medium'
                        variant='donut'
                      />
                  }
                ]
              }}
              cardsPerRow={[
                { cards: 1 },
                { minWidth: 640, cards: 2 },
                { minWidth: 960, cards: 3 },
                { minWidth: 1280, cards: 4 }
              ]}
              items={client.surveys}
              variant='full-page'
              empty={
                <RadBox textAlign='center' color='inherit' padding={{ top: 'xl' }}>
                  No surveys added to this client.
                </RadBox>
              }
            />
          </RadSpaceBetween>
        }
      />
    )
  }
}
