import React, { useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import moment from 'moment-timezone'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { usePost } from '../hooks/usePost'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadButtonDropdown } from '../common/RadButtonDropdown'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadExpandableSection } from '../common/RadExpandableSection'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadLink } from '../common/RadLink'
import { RadPagination } from '../common/RadPagination'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTable } from '../common/RadTable'
import { formatDateTime, numberWithCommas } from '../common/utilities'
moment.tz.setDefault('America/Chicago')

export function SurveyDetail () {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { surveyId } = useParams()
  const [selectedItems, setSelectedItems] = useState([])
  const [participantFilterText, setParticipantFilterText] = useState('')
  const [participantStatusFilter, setParticipantStatusFilter] = useState('')
  const [participantPageIndex, setParticipantPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [openResponseAnswersPageIndex, setOpenResponseAnswersPageIndex] = useState(1)
  const { data: survey } = useGet(`/api/survey/${surveyId}`, true)
  const { data: locations } = useGet(`/api/survey/${surveyId}/locations`)
  const { data: departments } = useGet(`/api/survey/${surveyId}/departments`)
  const { data: sponsors } = useGet(`/api/survey/${surveyId}/sponsors`)
  const { data: managers } = useGet(`/api/survey/${surveyId}/managers`)
  const { data: openResponseAnswers } = useGet(`/api/survey/${surveyId}/open-response-answers?offset=${(openResponseAnswersPageIndex - 1) * pageLength}&limit=${pageLength}`)
  const { data: participants, count } = useGet(`/api/survey/${surveyId}/participant?search=${participantFilterText}&status=${participantStatusFilter}&offset=${(participantPageIndex - 1) * pageLength}&limit=${pageLength}`)
  const confirmDelete = useConfirm('Delete survey?', 'Delete survey permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/survey/${surveyId}`, () => { navigate('/assessment') })
  const sendInvitations = usePost('/api/notification/send-invitation', { participants: selectedItems.map((x) => x.id) }, (resp) => { })

  let environmentURL
  switch (window.location.hostname.split('.')[0]) {
    case 'organizationalhealth':
      environmentURL = 'https://organizationalhealth.arrowheadconsulting.com'
      break
    case 'uat':
      environmentURL = 'https://uat.organizationalhealth.arrowheadconsulting.com'
      break
    case 'qa':
      environmentURL = 'https://qa.organizationalhealth.arrowheadconsulting.com'
      break
    default:
      environmentURL = 'http://localhost:3000'
  }

  function getSelfIdentification (value) {
    if (value === 0) {
      return '-'
    } else if (value === 1) {
      return 'Allow'
    } else if (value === 2) {
      return 'Require'
    }
  }

  if (
    survey != null &&
    locations != null &&
    departments != null &&
    participants != null &&
    sponsors != null &&
    managers != null &&
    openResponseAnswers != null
  ) {
    const questions = survey.questionSet.sections.flatMap((x) => x.questions)
    const statusOptions = [
      { value: '', label: 'All statuses' },
      { value: 'Not Started', label: 'Not started' },
      { value: 'Viewed', label: 'Viewed' },
      { value: 'In Progress', label: 'In progress' },
      { value: 'Done', label: 'Done' }
    ]

    const demographics = [
      { name: 'Department', selfIdentification: getSelfIdentification(survey.selfIdentifyDepartment) },
      { name: 'Subdepartment', selfIdentification: getSelfIdentification(survey.selfIdentifySubdepartment) },
      { name: 'Gender', selfIdentification: getSelfIdentification(survey.selfIdentifyGender) },
      { name: 'Race & Ethnicity', selfIdentification: getSelfIdentification(survey.selfIdentifyEthnicity) },
      { name: 'Age', selfIdentification: getSelfIdentification(survey.selfIdentifyAge) },
      { name: 'Years with Company', selfIdentification: getSelfIdentification(survey.selfIdentifyYearsWithCompany) },
      { name: 'Position Level', selfIdentification: getSelfIdentification(survey.selfIdentifyPositionLevel) },
      { name: 'Location', selfIdentification: getSelfIdentification(survey.selfIdentifyLocation) },
      { name: 'Work Environment', selfIdentification: getSelfIdentification(survey.selfIdentifyWorkEnvironment) },
      { name: 'Education Level', selfIdentification: getSelfIdentification(survey.selfIdentifyEducationLevel) }
    ].filter(e => e.selfIdentification !== '-')

    return (
      <RadAppLayout
        name={survey.name}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton iconName='external' iconAlign='right' href={`/assessment/${surveyId}/results`} target='_blank' ariaLabel='Results'>Results</RadButton>
                <RadButton onClick={() => navigate('edit')} disabled={moment().isAfter(moment(survey.endAt))}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={moment().isAfter(moment(survey.startAt))}>Delete</RadButton>
              </RadSpaceBetween>
            }
            description={survey.description}
          >
            {survey.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={4} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Organization
                  </RadBox>
                  <RadLink href={`/organization/${survey.client.id}`}>{survey.client.name}</RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Question Set
                  </RadBox>
                  <RadLink href={`/question-set/${survey.questionSet.id}`}>{survey.questionSet.name}</RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Heat Map
                  </RadBox>
                  <RadLink href={`/heat-map/${survey.heatMap.id}`}>{survey.heatMap.name}</RadLink>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Start
                  </RadBox>
                  {survey.startAt != null ? formatDateTime(survey.startAt) : '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    End
                  </RadBox>
                  {survey.endAt != null ? formatDateTime(survey.endAt) : '-'}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Paused
                  </RadBox>
                  {survey.isPaused ? 'Yes' : 'No'}
                  {survey.pausedMessage != null ? <div>{survey.pausedMessage}</div> : null}
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Closed
                  </RadBox>
                  {survey.isClosed ? 'Yes' : 'No'}
                  {survey.closedMessage != null ? <div>{survey.closedMessage}</div> : null}
                </div>
              </RadColumnLayout>
            </RadContainer>
            <RadExpandableSection
              headerText='Demographics'
              headerCounter={'(' + numberWithCommas(demographics.length) + ')'}
              variant='container'
              defaultExpanded
            >
              <RadTable
                variant='embedded'
                trackBy='id'
                items={demographics}
                columnDefinitions={[
                  {
                    id: 'name',
                    header: 'Name',
                    cell: e => e.name
                  },
                  {
                    id: 'selfIdentification',
                    header: 'Self-identification',
                    cell: e => e.selfIdentification
                  }
                ]}
                empty={
                  <RadBox textAlign='center' padding={{ top: 'l' }}>
                    <RadBox>
                      No demographics added to this survey.
                    </RadBox>
                  </RadBox>
                }
              />
            </RadExpandableSection>
            <RadExpandableSection
              headerText='Locations'
              headerCounter={'(' + numberWithCommas(locations.length) + ')'}
              variant='container'
              defaultExpanded
            >
              <RadTable
                variant='embedded'
                trackBy='id'
                items={locations}
                columnDefinitions={[
                  {
                    id: 'name',
                    header: 'Name',
                    cell: e => e.name
                  },
                  {
                    id: 'participantCount',
                    header: 'Participant count',
                    cell: e => e.participantCount
                  }
                ]}
                empty={
                  <RadBox textAlign='center' padding={{ top: 'l' }}>
                    <RadBox>
                      No locations added to this survey.
                    </RadBox>
                  </RadBox>
                }
              />
            </RadExpandableSection>
            <RadExpandableSection
              headerText='Departments'
              headerCounter={'(' + numberWithCommas(departments.length) + ')'}
              variant='container'
              defaultExpanded
            >
              <RadTable
                trackBy='id'
                variant='embedded'
                items={departments}
                columnDefinitions={[
                  {
                    id: 'name',
                    header: 'Department - Subdepartment',
                    cell: e => e.name
                  },
                  {
                    id: 'participantCount',
                    header: 'Participant count',
                    cell: e => e.participantCount > 0 ? e.participantCount : '-'
                  }
                ]}
                empty={
                  <RadBox textAlign='center' padding={{ top: 'l' }}>
                    <RadBox>
                      No departments added to this survey.
                    </RadBox>
                  </RadBox>
                }
              />
            </RadExpandableSection>
            <RadTable
              header={
                <RadHeader
                  counter={'(' + numberWithCommas(sponsors.length) + ')'}
                >
                  Sponsors
                </RadHeader>
              }
              trackBy='id'
              items={sponsors}
              columnDefinitions={[
                {
                  id: 'firstName',
                  header: 'First name',
                  cell: e => e.firstName
                },
                {
                  id: 'lastName',
                  header: 'Last name',
                  cell: e => e.lastName
                },
                {
                  id: 'email',
                  header: 'Email',
                  cell: e => e.email
                },
                {
                  id: 'link',
                  header: 'Link',
                  cell: e => <RadLink external externalIconAriaLabel='Opens in a new tab' target='_blank' href={`${environmentURL}/survey-results/${e.uuid}`}>link</RadLink>
                }
              ]}
              empty={
                <RadBox textAlign='center' padding={{ top: 'l' }}>
                  <RadBox>
                    No sponsors added to this survey.
                  </RadBox>
                </RadBox>
              }
            />
            <RadTable
              header={
                <RadHeader
                  counter={'(' + numberWithCommas(managers.length) + ')'}
                >
                  Managers
                </RadHeader>
              }
              trackBy='id'
              items={managers}
              columnDefinitions={[
                {
                  id: 'firstName',
                  header: 'First name',
                  cell: e => e.firstName
                },
                {
                  id: 'lastName',
                  header: 'Last name',
                  cell: e => e.lastName
                },
                {
                  id: 'email',
                  header: 'Email',
                  cell: e => e.email
                },
                {
                  id: 'departments',
                  header: 'Departments',
                  cell: e => e.departments.length > 0 ? <div>{e.departments.map((x) => <div key={`department-${x.id}`}>{x.name}{x.subdepartment != null ? ' - ' + x.subdepartment : ''}</div>)}</div> : '-'
                },
                {
                  id: 'link',
                  header: 'Link',
                  cell: e => <RadLink external externalIconAriaLabel='Opens in a new tab' target='_blank' href={`${environmentURL}/survey-results/${e.uuid}`}>link</RadLink>
                }
              ]}
              empty={
                <RadBox textAlign='center' padding={{ top: 'l' }}>
                  <RadBox>
                    No managers added to this survey.
                  </RadBox>
                </RadBox>
              }
            />
            <RadTable
              header={
                <RadHeader
                  counter={'(' + numberWithCommas(count) + ')'}
                  actions={
                    <RadSpaceBetween direction='horizontal' size='xs'>
                      <RadButtonDropdown
                        items={[
                          {
                            text: 'Send invitation',
                            id: 'send-invitation',
                            disabled: selectedItems.length === 0
                          }
                        ]}
                        onItemClick={({ detail }) => {
                          switch (detail.id) {
                            case 'send-invitation':
                              sendInvitations()
                              break
                            default:
                              break
                          }
                        }}
                      >
                        Actions
                      </RadButtonDropdown>
                    </RadSpaceBetween>
                  }
                >
                  Participants
                </RadHeader>
              }
              onSelectionChange={({ detail }) => setSelectedItems(detail.selectedItems)}
              selectedItems={selectedItems}
              ariaLabels={{
                selectionGroupLabel: 'Items selection',
                allItemsSelectionLabel: ({ selectedItems }) =>
                  `${selectedItems.length} ${
                    selectedItems.length === 1 ? 'item' : 'items'
                  } selected`,
                itemSelectionLabel: ({ selectedItems }, item) => {
                  const isItemSelected = selectedItems.filter(
                    i => i.name === item.name
                  ).length
                  return `${item.name} is ${
                    isItemSelected ? '' : 'not'
                  } selected`
                }
              }}
              selectionType='multi'
              trackBy='id'
              filter={
                <RadGrid
                  gridDefinition={[
                    { colspan: { default: 12, xs: 9 } },
                    { colspan: { default: 12, xs: 3 } }
                  ]}
                >
                  <RadInput
                    placeholder='Search'
                    ariaLabel='Search'
                    onChange={({ detail }) => {
                      setParticipantPageIndex(1)
                      setParticipantFilterText(detail.value)
                      setSelectedItems([])
                    }}
                    value={participantFilterText}
                    type='search'
                  />
                  <RadSelect
                    selectedOption={statusOptions.find(x => x.value === participantStatusFilter)}
                    onChange={({ detail }) => {
                      setParticipantStatusFilter(detail.selectedOption.value)
                    }}
                    options={statusOptions}
                    selectedAriaLabel='Selected'
                    empty='No matches found'
                  />
                </RadGrid>
              }
              pagination={
                <RadPagination
                  currentPageIndex={participantPageIndex}
                  pagesCount={Math.ceil(count / pageLength)}
                  onChange={({ detail }) => {
                    searchParams.set('page', detail.currentPageIndex)
                    setSearchParams(searchParams)
                    setParticipantPageIndex(detail.currentPageIndex)
                  }}
                  ariaLabels={{
                    nextPageLabel: 'Next page',
                    previousPageLabel: 'Previous page',
                    pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                  }}
                />
              }
              items={participants}
              columnDefinitions={[
                {
                  id: 'firstName',
                  header: 'First name',
                  cell: e => e.firstName
                },
                {
                  id: 'lastName',
                  header: 'Last name',
                  cell: e => e.lastName
                },
                {
                  id: 'email',
                  header: 'Email',
                  cell: e => e.email
                },
                {
                  id: 'percentComplete',
                  header: 'Status',
                  cell: e => {
                    switch (e.status) {
                      case 'In Progress':
                        return `${(e.answers.filter(a => questions.find(q => q.id === a.questionId).isOptional === false).length / questions.filter(q => q.isOptional === false).length * 100).toFixed(0)}%`
                      default:
                        return e.status
                    }
                  }
                },
                {
                  id: 'link',
                  header: 'Link',
                  cell: e => <RadLink external externalIconAriaLabel='Opens in a new tab' target='_blank' href={`${environmentURL}/survey/${e.uuid}`}>link</RadLink>
                }
              ]}
              empty={
                <RadBox textAlign='center' padding={{ top: 'l' }}>
                  <RadBox>
                    {participantFilterText === '' ? 'No participants added to this survey.' : 'No matches found.'}
                  </RadBox>
                </RadBox>
              }
            />
            <RadExpandableSection
              headerText='Open Response Answers'
              headerCounter={'(' + numberWithCommas(openResponseAnswers.length) + ')'}
              variant='container'
              defaultExpanded
            >
              <RadTable
                variant='embedded'
                trackBy='id'
                items={openResponseAnswers}
                columnDefinitions={[
                  {
                    id: 'question',
                    header: 'Question',
                    cell: e => e.question
                  },
                  {
                    id: 'answer',
                    header: 'Answer',
                    cell: e => e.answer
                  }
                ]}
                pagination={
                  <RadPagination
                    currentPageIndex={participantPageIndex}
                    pagesCount={Math.ceil(openResponseAnswers.length / pageLength)}
                    onChange={({ detail }) => {
                      searchParams.set('page', detail.currentPageIndex)
                      setOpenResponseAnswersPageIndex(detail.currentPageIndex)
                    }}
                    ariaLabels={{
                      nextPageLabel: 'Next page',
                      previousPageLabel: 'Previous page',
                      pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                    }}
                  />
                }
                empty={
                  <RadBox textAlign='center' padding={{ top: 'l' }}>
                    <RadBox>
                      No open response answers added to this survey.
                    </RadBox>
                  </RadBox>
                }
              />
            </RadExpandableSection>
          </RadSpaceBetween>
        }
      />
    )
  }
}
