import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { useIsMobile } from '../hooks/useIsMobile'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadDivider } from '../common/RadDivider'
import { RadFileUpload } from '../common/RadFileUpload'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'
import { toBase64 } from '../common/utilities'
import { RadCheckbox } from '../common/RadCheckbox'

export function QuestionCategoryEdit () {
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const { questionCategoryId } = useParams()
  const [formValues, setFormValues] = useState()
  const [files, setFiles] = useState([])
  const { data: questionCategory } = useGet(questionCategoryId ? `/api/question-category/${questionCategoryId}` : null)
  const { data: surveyTypeOptions } = useGet('/api/option/survey-type')

  useEffect(() => {
    const defaultFormValues = { isDisabled: false, subcategories: [] }
    setFormValues(questionCategory ?? defaultFormValues)
    if (questionCategory?.icon != null) {
      const file = new File(
        [],
        questionCategory.icon.path.split('/').pop(),
        { type: questionCategory.icon.type, lastModifiedDate: questionCategory.icon.updatedAt }
      )
      setFiles([file])
    }
  }, [questionCategory])

  const create = usePost('/api/question-category', formValues, (resp) => { navigate(`/question-category/${resp.id}`) })
  const update = usePut(`/api/question-category/${questionCategoryId}`, formValues, (resp) => { navigate(`/question-category/${questionCategoryId}`) })

  async function saveChanges () {
    if (questionCategoryId != null) { update() } else { create() }
  }

  async function cancel () {
    if (questionCategoryId != null) {
      navigate(`/question-category/${questionCategoryId}`)
    } else {
      navigate('/question-category')
    }
  }

  if (
    formValues != null &&
    surveyTypeOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Question Category' : 'New Question Category'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                    <RadFormField label='Assessment Type *' field='surveyTypeId'>
                      <RadSelect
                        selectedOption={formValues.surveyTypeId ? surveyTypeOptions.find(x => x.value === formValues.surveyTypeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, surveyTypeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, surveyTypeId: null })
                          }
                        }}
                        options={surveyTypeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadFormField label='Icon' field='icon'>
                      <RadFileUpload
                        onChange={async ({ detail }) => {
                          setFiles(detail.value)
                          if (detail.value[0] != null) {
                            const icon = {
                              name: detail.value[0].name,
                              type: detail.value[0].type
                            }
                            icon.data = await toBase64(detail.value[0])
                            setFormValues({ ...formValues, icon })
                          } else {
                            setFormValues({ ...formValues, icon: null })
                          }
                        }}
                        value={files}
                        i18nStrings={{
                          uploadButtonText: e => e ? 'Choose files' : 'Choose file',
                          dropzoneText: e =>
                            e
                              ? 'Drop files to upload'
                              : 'Drop file to upload',
                          removeFileAriaLabel: e =>
                          `Remove file ${e + 1}`,
                          limitShowFewer: 'Show fewer files',
                          limitShowMore: 'Show more files',
                          errorIconAriaLabel: 'Error'
                        }}
                        showFileThumbnail={false}
                        tokenLimit={1}
                      />
                    </RadFormField>
                    <RadFormField label='Disabled' field='isDisabled'>
                      <RadCheckbox
                        checked={formValues.isDisabled}
                        onChange={({ detail }) => {
                          setFormValues({ ...formValues, isDisabled: detail.checked })
                        }}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + formValues.subcategories.length + ')'}
                    >
                      Subcategories
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween direction='vertical' size='s'>
                    {formValues.subcategories.length === 0 &&
                      <RadBox padding={{ top: 'xs' }} color='text-body-secondary'>No subdepartments added to this department.</RadBox>}
                    {formValues.subcategories.map((subcategory) =>
                      <SubcategoryEditor
                        key={`subcategory-${subcategory.id || subcategory.uuid}}`}
                        item={subcategory}
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />)}
                    {isMobile && <RadDivider />}
                    <RadButton
                      onClick={() => {
                        const departments = formValues.departments
                        formValues.subcategories.push({ uuid: uuidv4(), isDisabled: false })
                        setFormValues({ ...formValues, departments })
                      }}
                    >
                      Add new subcategory
                    </RadButton>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function SubcategoryEditor ({ item, formValues, setFormValues }) {
  const [icons, setIcons] = useState([])
  const subcategories = formValues.subcategories

  useEffect(() => {
    if (item?.icon != null) {
      const file = new File(
        [],
        item.icon.path.split('/').pop(),
        { type: item.icon.type, lastModifiedDate: item.icon.updatedAt }
      )
      setIcons([file])
    }
  }, [item])

  // async function getDestination (file) {
  //   const token = await user.getIdToken()
  //   const response = await fetch(
  //     `/api/question-category/upload-url?length=${file.size}`,
  //     {
  //       method: 'GET',
  //       headers: { Authorization: `Bearer ${token}` }
  //     })
  //   const data = await response.json()
  //   return data.destination
  // }

  // async function uploadVideo (file, destination) {
  //   var mediaId = ''

  //   var options = {
  //     endpoint: destination,
  //     // headers: {
  //     //   'Access-Control-Expose-Headers': 'Location',
  //     //   'Access-Control-Allow-Headers': '*',
  //     //   'Access-Control-Allow-Origin': '*',
  //     //   Location: destination
  //     // },
  //     chunkSize: 50 * 1024 * 1024,
  //     retryDelays: [0, 3000, 5000, 10000, 20000],
  //     metadata: {
  //       filename: file.name,
  //       filetype: file.type,
  //       defaulttimestamppct: 0.5
  //     },
  //     // uploadSize: byteCharacters.length,
  //     onError: function (error) {
  //       throw error
  //     },
  //     onProgress: function (bytesUploaded, bytesTotal) {
  //       var percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2)
  //       setProgress(percentage)
  //       console.log(bytesUploaded, bytesTotal, percentage + '%')
  //     },
  //     onSuccess: function () {
  //       console.log('Upload finished')
  //       setProgress(null)
  //     },
  //     onAfterResponse: function (req, res) {
  //       return new Promise(resolve => {
  //         var mediaIdHeader = res.getHeader('stream-media-id')
  //         if (mediaIdHeader) {
  //           mediaId = mediaIdHeader
  //         }
  //         resolve(mediaId)
  //       })
  //     }
  //   }

  //   var upload = new Upload(file, options)
  //   upload.start()
  // }

  return (
    <RadSpaceBetween size='s'>
      <div>
        <RadBox float='right' padding={{ top: 'l' }}>
          <RadButton
            disabled={item.questionCount !== '0'}
            wrapText={false}
            onClick={() => {
              const newSubcategories = subcategories.filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, subcategories: newSubcategories })
            }}
          >
            Remove subcategory
          </RadButton>
        </RadBox>
        <RadFormField label='Name *' field={`subcategories.${item.id ?? item.uuid}.name`}>
          <RadInput
            placeholder='Enter name'
            ariaRequired
            value={item.name}
            onChange={({ detail }) => {
              item.name = detail.value
              setFormValues({ ...formValues, subcategories })
            }}
          />
        </RadFormField>
      </div>
      <RadFormField label='Icon *' field={`subcategories.${item.id ?? item.uuid}.name`}>
        <RadFileUpload
          onChange={async ({ detail }) => {
            setIcons(detail.value)
            if (detail.value[0] != null) {
              const icon = {
                name: detail.value[0].name,
                type: detail.value[0].type
              }
              icon.data = await toBase64(detail.value[0])
              item.icon = icon
            } else {
              item.icon = null
            }
            setFormValues({ ...formValues, subcategories })
          }}
          value={icons}
          i18nStrings={{
            uploadButtonText: e => e ? 'Choose files' : 'Choose file',
            dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
            removeFileAriaLabel: e => `Remove file ${e + 1}`,
            limitShowFewer: 'Show fewer files',
            limitShowMore: 'Show more files',
            errorIconAriaLabel: 'Error'
          }}
          showFileThumbnail={false}
          tokenLimit={1}
        />
      </RadFormField>
      <RadFormField label='Heat Map Description *' field={`subcategories.${item.id ?? item.uuid}.heatMapDescription`}>
        <RadTextarea
          placeholder='Enter heat map description'
          ariaRequired
          value={item.heatMapDescription}
          onChange={({ detail }) => {
            item.heatMapDescription = detail.value
            setFormValues({ ...formValues, subcategories })
          }}
        />
      </RadFormField>
      <RadFormField label='Report Description *' field={`subcategories.${item.id ?? item.uuid}.reportDescription`}>
        <RadTextarea
          placeholder='Enter report description'
          ariaRequired
          value={item.reportDescription}
          onChange={({ detail }) => {
            item.reportDescription = detail.value
            setFormValues({ ...formValues, subcategories })
          }}
        />
      </RadFormField>
      {/* <RadFormField label='Video *' field={`subcategories.${item.id ?? item.uuid}.video`}>
        <RadFileUpload
          onChange={async ({ detail }) => {
            setVideos(detail.value)
            // console.log(detail.value[0])
            if (detail.value[0] != null) {
              const video = {
                name: detail.value[0].name,
                type: detail.value[0].type
              }
              // video.data = await toBase64(detail.value[0])
              item.video = video
              const destination = await getDestination(detail.value[0])
              console.log(destination)
              uploadVideo(detail.value[0], destination)
            } else {
              item.video = null
            }
            setFormValues({ ...formValues, subcategories })
          }}
          value={videos}
          i18nStrings={{
            uploadButtonText: e => e ? 'Choose files' : 'Choose file',
            dropzoneText: e => e ? 'Drop files to upload' : 'Drop file to upload',
            removeFileAriaLabel: e => `Remove file ${e + 1}`,
            limitShowFewer: 'Show fewer files',
            limitShowMore: 'Show more files',
            errorIconAriaLabel: 'Error'
          }}
          showFileThumbnail={false}
          tokenLimit={1}
        />
      </RadFormField> */}
      <RadFormField label='Video Link *' field={`subcategories.${item.id ?? item.uuid}.videoLink`}>
        <RadTextarea
          rows={5}
          placeholder='Enter video link'
          ariaRequired
          value={item.videoLink}
          onChange={({ detail }) => {
            item.videoLink = detail.value
            setFormValues({ ...formValues, subcategories })
          }}
        />
      </RadFormField>
      <RadFormField label='Disabled' field={`subcategories.${item.id ?? item.uuid}.isDisabled`}>
        <RadCheckbox
          checked={item.isDisabled}
          onChange={({ detail }) => {
            item.isDisabled = detail.checked
            setFormValues({ ...formValues, subcategories })
          }}
        />
      </RadFormField>
      <RadDivider />
    </RadSpaceBetween>
  )
}
