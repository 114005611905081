import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function HeatMapEdit () {
  const navigate = useNavigate()
  const { heatMapId } = useParams()
  const [formValues, setFormValues] = useState()
  const { data: heatMap } = useGet(heatMapId ? `/api/heat-map/${heatMapId}` : null)
  const { data: surveyTypeOptions } = useGet('/api/option/survey-type')

  useEffect(() => {
    const defaultFormValues = { ranges: [] }
    setFormValues(heatMap ?? defaultFormValues)
  }, [heatMap])

  const create = usePost('/api/heat-map', formValues, (resp) => { navigate(`/heat-map/${resp.id}`) })
  const update = usePut(`/api/heat-map/${heatMapId}`, formValues, (resp) => { navigate(`/heat-map/${heatMapId}`) })

  async function saveChanges () {
    if (heatMapId != null) { update() } else { create() }
  }

  async function cancel () {
    if (heatMapId != null) {
      navigate(`/heat-map/${heatMapId}`)
    } else {
      navigate('/heat-map')
    }
  }

  if (
    formValues != null &&
    surveyTypeOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Heat Map' : 'New Heat Map'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                        disabled={formValues.isProtected}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                <RadContainer
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + formValues.ranges.length + ')'}
                    >
                      Ranges
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    {formValues.ranges.map((x) =>
                      <RangeEditor
                        key={`range-${x.id},${x.uuid}`}
                        item={x}
                        formValues={formValues}
                        setFormValues={setFormValues}
                      />)}
                    <RadButton
                      onClick={() => {
                        const ranges = formValues.ranges
                        ranges.push({ uuid: uuidv4(), selectable: false })
                        setFormValues({ ...formValues, ranges })
                      }}
                    >
                      Add new range
                    </RadButton>
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}

function RangeEditor ({ item, formValues, setFormValues, heatMap }) {
  const ranges = formValues.ranges

  return (
    <RadSpaceBetween size='l'>
      <RadGrid
        gridDefinition={[
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } },
          { colspan: { default: 12, xxs: 2 } }
        ]}
      >
        <RadFormField label='Name *' field={`ranges.${item.id ?? item.uuid}.name`}>
          <RadInput
            placeholder='Enter name'
            ariaRequired
            value={item.name}
            onChange={({ detail }) => {
              item.name = detail.value
              setFormValues({ ...formValues, ranges })
            }}
          />
        </RadFormField>
        <RadFormField label='Min value *' field={`ranges.${item.id ?? item.uuid}.minValue`}>
          <RadInput
            placeholder='Enter min value'
            ariaRequired
            value={item.minValue}
            onChange={({ detail }) => {
              item.minValue = detail.value
              setFormValues({ ...formValues, ranges })
            }}
          />
        </RadFormField>
        <RadFormField label='Max value *' field={`ranges.${item.id ?? item.uuid}.maxValue`}>
          <RadInput
            placeholder='Enter max value'
            ariaRequired
            value={item.maxValue}
            onChange={({ detail }) => {
              item.maxValue = detail.value
              setFormValues({ ...formValues, ranges })
            }}
          />
        </RadFormField>
        <RadFormField label='Color *' field={`ranges.${item.id ?? item.uuid}.color`}>
          <RadInput
            placeholder='Enter color'
            ariaRequired
            value={item.color}
            onChange={({ detail }) => {
              item.color = detail.value
              setFormValues({ ...formValues, ranges })
            }}
          />
        </RadFormField>
        <RadFormField label='Selectable *' field={`ranges.${item.id ?? item.uuid}.selectable`}>
          <RadCheckbox
            checked={item.selectable}
            onChange={({ detail }) => {
              item.selectable = detail.checked
              setFormValues({ ...formValues, ranges })
            }}
          />
        </RadFormField>
        <RadBox padding={{ top: item === formValues.ranges[0] ? 'xl' : null }}>
          <RadButton
            onClick={() => {
              formValues.ranges = formValues.ranges
                .filter((x) => x.id !== item.id || x.uuid !== item.uuid)
              setFormValues({ ...formValues, sections: ranges })
            }}
          >
            Remove range
          </RadButton>
        </RadBox>
      </RadGrid>
      <RadFormField label='Description *' field={`ranges.${item.id ?? item.uuid}.description`}>
        <RadTextarea
          placeholder='Enter description'
          ariaRequired
          rows={6}
          value={item.description}
          onChange={({ detail }) => {
            item.description = detail.value
            setFormValues({ ...formValues, ranges })
          }}
        />
      </RadFormField>
      {/* <RadDivider /> */}
    </RadSpaceBetween>
  )
}
