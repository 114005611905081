import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadFormField } from '../common/RadFormField'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function YesNo ({ question, formValues, setFormValues }) {
  const answers = formValues.answers
  const participant = formValues.participants[0]
  let answer = answers.find((x) => x.questionId === question.id)
  if (answer == null) {
    answer = { surveyParticipantId: participant.id, questionId: question.id, value: null }
    answers.push(answer)
  }

  return (
    <RadSpaceBetween>
      <RadBox variant='awsui-key-label'>
        {`${question.text} - ${question.category.name}: ${question.subcategory.name}${question.isOptional || question.isInternal ? ' -' : ''}`}
        <i>{`${question.isOptional ? ' optional' : ''}${question.isInternal ? ' internal' : ''}`}</i>
      </RadBox>
      <RadFormField>
        {question.context != null && <RadBox color='text-body-secondary' padding={{ bottom: 'xs' }}>{question.context}</RadBox>}
        <RadCheckbox
          checked={answer.value === true}
          onChange={({ detail }) => {
            answer.value = detail.checked
            setFormValues({ ...formValues, answers })
          }}
        >
          Yes
        </RadCheckbox>
        <RadCheckbox
          checked={answer.value === false}
          onChange={({ detail }) => {
            answer.value = !detail.checked
            setFormValues({ ...formValues, answers })
          }}
        >
          No
        </RadCheckbox>
      </RadFormField>
    </RadSpaceBetween>
  )
}
