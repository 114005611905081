import React from 'react'
import { RadBox } from '../common/RadBox'
import { RadFormField } from '../common/RadFormField'
import { RadInput } from '../common/RadInput'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function Number ({ question, formValues, setFormValues }) {
  const participants = formValues.participants
  const participant = participants[0]
  const answers = participant.answers
  let answer = answers.find((x) => x.questionId === question.id)

  return (
    <RadSpaceBetween>
      <RadBox variant='awsui-key-label'>
        {`${question.text} ${question.category != null ? ' - ' + question.category.name : ''} ${question.subcategory != null ? ' : ' + question.subcategory.name : ''}${question.isOptional || question.isInternal ? ' -' : ''}`}
        <i>{`${question.isOptional ? ' optional' : ''}${question.isInternal ? ' internal' : ''}`}</i>
      </RadBox>
      <RadFormField>
        {question.context != null && <RadBox color='text-body-secondary' padding={{ bottom: 'xs' }}>{question.context}</RadBox>}
        <RadInput
          placeholder='Enter number'
          value={answer?.text}
          onChange={({ detail }) => {
            if (answer == null) {
              answer = { surveyParticipantId: participant.id, questionId: question.id, text: null, uuid: 'chipi' }
              answers.push(answer)
            }
            answer.text = detail.value
            setFormValues({ ...formValues, answers })
          }}
        />
      </RadFormField>
    </RadSpaceBetween>
  )
}
