
    import './styles.scoped.css';
    export default {
  "drawer": "awsui_drawer_1r9lg_1mkq8_189",
  "refresh": "awsui_refresh_1r9lg_1mkq8_189",
  "animating": "awsui_animating_1r9lg_1mkq8_203",
  "preference-icon--layout-border": "awsui_preference-icon--layout-border_1r9lg_1mkq8_226",
  "preference-icon--layout-background": "awsui_preference-icon--layout-background_1r9lg_1mkq8_230",
  "preference-icon--layout-header": "awsui_preference-icon--layout-header_1r9lg_1mkq8_233",
  "preference-icon--border": "awsui_preference-icon--border_1r9lg_1mkq8_236",
  "preference-icon--primary-button": "awsui_preference-icon--primary-button_1r9lg_1mkq8_240",
  "preference-icon--secondary": "awsui_preference-icon--secondary_1r9lg_1mkq8_243",
  "preference-icon--disabled-element": "awsui_preference-icon--disabled-element_1r9lg_1mkq8_246",
  "preference-icon--separator": "awsui_preference-icon--separator_1r9lg_1mkq8_249",
  "preference-icon--focus-text": "awsui_preference-icon--focus-text_1r9lg_1mkq8_252",
  "preference-icon-refresh--layout-top": "awsui_preference-icon-refresh--layout-top_1r9lg_1mkq8_256",
  "preference-icon-refresh--layout-main": "awsui_preference-icon-refresh--layout-main_1r9lg_1mkq8_259",
  "preference-icon-refresh--primary": "awsui_preference-icon-refresh--primary_1r9lg_1mkq8_262",
  "preference-icon-refresh--disabled": "awsui_preference-icon-refresh--disabled_1r9lg_1mkq8_265",
  "preference-icon-refresh--column-header": "awsui_preference-icon-refresh--column-header_1r9lg_1mkq8_268",
  "preference-icon-refresh--window": "awsui_preference-icon-refresh--window_1r9lg_1mkq8_271",
  "preference-icon-refresh--secondary": "awsui_preference-icon-refresh--secondary_1r9lg_1mkq8_275",
  "preference-icon-refresh--default": "awsui_preference-icon-refresh--default_1r9lg_1mkq8_278",
  "preference-icon-refresh--separator": "awsui_preference-icon-refresh--separator_1r9lg_1mkq8_281",
  "preference-icon-refresh--input-default": "awsui_preference-icon-refresh--input-default_1r9lg_1mkq8_284",
  "preference-icon-refresh--heading": "awsui_preference-icon-refresh--heading_1r9lg_1mkq8_287",
  "resize-active": "awsui_resize-active_1r9lg_1mkq8_292",
  "resize-side": "awsui_resize-side_1r9lg_1mkq8_298",
  "resize-bottom": "awsui_resize-bottom_1r9lg_1mkq8_301",
  "root": "awsui_root_1r9lg_1mkq8_306",
  "preferences-button": "awsui_preferences-button_1r9lg_1mkq8_319",
  "close-button": "awsui_close-button_1r9lg_1mkq8_323",
  "drawer-closed": "awsui_drawer-closed_1r9lg_1mkq8_334",
  "drawer-content-side": "awsui_drawer-content-side_1r9lg_1mkq8_339",
  "drawer-content-bottom": "awsui_drawer-content-bottom_1r9lg_1mkq8_359",
  "position-bottom": "awsui_position-bottom_1r9lg_1mkq8_368",
  "position-side": "awsui_position-side_1r9lg_1mkq8_395",
  "slider": "awsui_slider_1r9lg_1mkq8_401",
  "slider-side": "awsui_slider-side_1r9lg_1mkq8_430",
  "slider-wrapper-bottom": "awsui_slider-wrapper-bottom_1r9lg_1mkq8_435",
  "slider-wrapper-side": "awsui_slider-wrapper-side_1r9lg_1mkq8_446",
  "open-button": "awsui_open-button_1r9lg_1mkq8_457",
  "open-button-side": "awsui_open-button-side_1r9lg_1mkq8_461",
  "slider-icon": "awsui_slider-icon_1r9lg_1mkq8_468",
  "slider-icon-bottom": "awsui_slider-icon-bottom_1r9lg_1mkq8_474",
  "slider-icon-side": "awsui_slider-icon-side_1r9lg_1mkq8_477",
  "pane-header-wrapper-bottom": "awsui_pane-header-wrapper-bottom_1r9lg_1mkq8_481",
  "drawer-mobile": "awsui_drawer-mobile_1r9lg_1mkq8_491",
  "drawer-disable-content-paddings": "awsui_drawer-disable-content-paddings_1r9lg_1mkq8_494",
  "content-bottom": "awsui_content-bottom_1r9lg_1mkq8_502",
  "pane-bottom-center-align": "awsui_pane-bottom-center-align_1r9lg_1mkq8_513",
  "pane-bottom-content-nav-padding": "awsui_pane-bottom-content-nav-padding_1r9lg_1mkq8_518",
  "pane-bottom-content-tools-padding": "awsui_pane-bottom-content-tools-padding_1r9lg_1mkq8_522",
  "content-bottom-max-width": "awsui_content-bottom-max-width_1r9lg_1mkq8_526",
  "content-side": "awsui_content-side_1r9lg_1mkq8_531",
  "pane-header-wrapper-side": "awsui_pane-header-wrapper-side_1r9lg_1mkq8_542",
  "pane-content-wrapper-side": "awsui_pane-content-wrapper-side_1r9lg_1mkq8_545",
  "header": "awsui_header_1r9lg_1mkq8_550",
  "header-text": "awsui_header-text_1r9lg_1mkq8_559",
  "header-divider": "awsui_header-divider_1r9lg_1mkq8_569",
  "header-actions": "awsui_header-actions_1r9lg_1mkq8_575",
  "divider": "awsui_divider_1r9lg_1mkq8_583"
};
  