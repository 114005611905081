import React from 'react'
import { RadFormField } from '../common/RadFormField'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadBox } from '../common/RadBox'
import { RadSpaceBetween } from '../common/RadSpaceBetween'

export function SelectAllThatApply ({ question, formValues, setFormValues }) {
  const participants = formValues.participants
  const participant = participants[0]
  const answers = participant.answers
  let answer = answers.find((x) => x.questionId === question.id)
  const items = question.answers.map((a) => ({ value: a.text, label: a.text }))
    .concat(question.hasOther ? [{ value: 'Other', label: 'Other' }] : [])

  return (
    <RadSpaceBetween>
      <RadBox variant='awsui-key-label'>
        {`${question.text} ${question.category != null ? ' - ' + question.category.name : ''} ${question.subcategory != null ? ' : ' + question.subcategory.name : ''}${question.isOptional || question.isInternal ? ' -' : ''}`}
        <i>{`${question.isOptional ? ' optional' : ''}${question.isInternal ? ' internal' : ''}`}</i>
      </RadBox>
      <RadFormField>
        {question.context != null && <RadBox color='text-body-secondary' padding={{ bottom: 'xs' }}>{question.context}</RadBox>}
        <RadSpaceBetween size='xxs'>
          {items.map((item) => {
            return (
              <RadCheckbox
                key={`answer-${item.value}`}
                checked={answer?.text.split(',').find((x) => x === item.label) != null}
                onChange={({ detail }) => {
                  if (answer == null) {
                    answer = { surveyParticipantId: participant.id, questionId: question.id, text: null, uuid: 'chipi' }
                    answers.push(answer)
                  }
                  if (detail.checked) {
                    answer.text = (answer?.text ?? '').split(',').concat(item.label).join(',')
                  } else {
                    answer.text = answer?.text.split(',').filter((x) => x !== item.label).join(',')
                    if (answer.text === '') answer.text = null
                  }
                  console.log(answer.text)
                  setFormValues({ ...formValues, answers })
                }}
              >
                {item.label}
              </RadCheckbox>
            )
          })}
        </RadSpaceBetween>
      </RadFormField>
    </RadSpaceBetween>
  )
}
